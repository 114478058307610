import { Button } from "@material-ui/core";
import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useDefineUserRole } from "../../../hooks";

const ButtonAddProduct: React.FC = () => {
  const intl = useIntl();
  const { me } = useSelector(({ profile }: any) => profile);
  const history = useHistory();

  const isRoleAdmin = useDefineUserRole(me, 'ROLE_ADMIN');
  const isRoleVendor = useDefineUserRole(me, 'ROLE_VENDOR');
  const isRoleManager = useDefineUserRole(me, 'ROLE_MANAGER');

  const goToCreateProduct = useCallback(() => {
    if (me?.is_admin) {
      history.push(`/products/create`);
    } else {
      history.push(`/products/create/company/${me?.company?.id}/${me?.company?.name}`);
    }
  }, [history, me]);

  return (
    <>
      {(isRoleAdmin || isRoleManager || isRoleVendor) && (
        <Button
          color='primary'
          variant='contained'
          onClick={goToCreateProduct}
        >
          {intl.formatMessage({ id: 'CATEGORY.BUTTON.ADD.PRODUCT' })}
        </Button>
      )}
    </>
  );
}

export default ButtonAddProduct;
