import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import Rating from './Rating';
import { toAbsoluteUrl } from '../../../../../_metronic';
import { API_DOMAIN } from '../../../../constants';
import Preloader from '../../../../components/ui/Preloader/Preloader';
import { useDefineUserRole, useFormatMessage } from '../../../../hooks';
import { IAppState } from '../../../../store/rootDuck';
import { useGetReviews } from '../hooks/useGetReviews';
import { useDeleteReview } from '../hooks/useDeleteReview';
import { Button } from '../../../../components/ui/Buttons';
import { IProduct } from '../../../../interfaces/product';
import { useStylesReviewsForm } from '../hooks/useStylesReviewForm';

interface IProps {
  meAdd?: boolean;
  product: IProduct;
  name: string;
}

const ReviewsForm: React.FC<IProps> = ({ meAdd = false, product, name }) => {
  const classes = useStylesReviewsForm();
  const fm = useFormatMessage();
  const reviewsProduct = useSelector(
    ({ products: { reviewsProduct } }: IAppState) => reviewsProduct,
    shallowEqual
  );
  const me = useSelector(({ profile: { me } }: IAppState) => me, shallowEqual);
  const isAdmin = useDefineUserRole(me, 'ROLE_ADMIN');
  const productId = useMemo(() => product.id, [product]);

  const [fetchReviews, loading] = useGetReviews();
  const [deleteReview] = useDeleteReview();
  const [pageReviews, setPageReviews] = useState({ perPage: 20, page: 1 });

  useEffect(() => {
    productId && fetchReviews(productId, pageReviews.perPage, pageReviews.page);
  }, [productId, fetchReviews]);

  const handleDeleteReview = useCallback(
    (id: number) => {
      deleteReview(id, productId, pageReviews.perPage, pageReviews.page);
    },
    [productId, pageReviews]
  );

  if (loading) return <Preloader />;

  return (
    <>
      <div className={classes.headerTitle}>{fm('PRODUCT.REVIEW')}</div>

      <div className={classes.reviews}>
        <div className={classes.reviewsList}>
          {reviewsProduct && reviewsProduct.reviews.length > 0 && (
            <>
              {reviewsProduct.reviews.map(item => (
                <>
                  <div className={classes.review}>
                    <div className={classes.containerReview}>
                      <div className={classes.avatar}>
                        <img
                          alt={item.user.fio}
                          src={
                            item.user.avatar
                              ? `${API_DOMAIN}/${item.user.avatar}`
                              : toAbsoluteUrl('/images/placeholder.png')
                          }
                        />
                      </div>

                      <div className={classes.reviewUser}>
                        <Typography className={classes.btnText}>
                          {item.user.id === me?.id ? fm('REVIEW.MY') : item.user.fio}
                        </Typography>
                      </div>
                      <Rating rating={item.rating} />
                    </div>
                    <div className={classes.reviewInfo}>
                      <Typography className={classes.description}>{item.text}</Typography>
                      {(isAdmin || item.user.id === me?.id) && (
                        <Button
                          onClick={() => handleDeleteReview(item.id)}
                          variant='outlined'
                          type='submit'
                          // className={classes.buttonForm}
                          color='inherit'
                          className={classes.btn}
                        >
                          <div className={classes.btnText}>
                            {fm('PRODUCT.REVIEW.BUTTON.DEL')}
                          </div>
                        </Button>
                      )}
                    </div>
                  </div>
                </>
              ))}
              <div className={classes.actions}>
                {reviewsProduct.total !== reviewsProduct.reviews.length && (
                  <Button style={{ cursor: 'pointer' }} color='primary' onClick={() => {}}>
                    {fm('PRODUCT.REVIEW.LOADMORE')}
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ReviewsForm;
