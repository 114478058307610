import { Button } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

const ButtonAddTender: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();

  const goToCreateTender = useCallback(() => {
    history.push(`/product_search/create`);
  }, [history]);

  return (
    <>
      <Button color='primary' variant='contained' onClick={goToCreateTender}>
        {intl.formatMessage({ id: 'TENDERS.NEW' })}
      </Button>
    </>
  );
};

export default ButtonAddTender;
