import React from 'react';

interface IProps {
  size?: string;
}

const StarDisable: React.FC<IProps> = ({ size }) => {
  return (
    <>
      <svg width={size || "25"} height={size || "25"} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
        <path d="m12.5 2.26 3.01 6.098 6.73.978-4.87 4.746 1.15 6.703-6.02-3.165-6.02 3.165 1.15-6.703-4.87-4.746 6.73-.978L12.5 2.26z" stroke="#D01C1F" strokeWidth="2" fill="none" fillRule="evenodd"/>
      </svg>
    </>
  );
};

export default StarDisable;
