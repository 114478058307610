import React from 'react';
import { Button, Dialog, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';

interface IAlertDialog extends DialogProps {
  handleAgree: any;
  handleClose: any;
  okText?: string;
  cancelText?: string;
  message?: string;
}

const AlertDialog: React.FC<IAlertDialog> = ({
  open,
  handleClose,
  handleAgree,
  okText = 'OK',
  cancelText = 'Cancel',
  message = '',
}) => (
  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogContent>
      <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleAgree} color="primary">
        {okText}
      </Button>
      <Button onClick={handleClose} color="primary" autoFocus>
        {cancelText}
      </Button>
    </DialogActions>
  </Dialog>
);

export default AlertDialog;
