import React, {useState,useCallback, ChangeEvent} from 'react';
import {
    makeStyles,
    createStyles,
    Card,
    CardContent,
    Typography,
    TextField,
    MenuItem
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { Link , useHistory } from 'react-router-dom';
import { API_DOMAIN } from '../../../../constants';
import AlertCompanyDetail from '../../products/components/AlertCompanyDetail';
import homeStyles from '../../homeStyles';
import { ICompany } from '../../../../interfaces/companies';
import { thousands } from '../../../../utils/utils';
import { IOrder, IOrderItem } from '../../../../interfaces/order';
import { IProduct } from '../../../../interfaces/product';
import ButtonWithLoader from '../../../../components/ui/Buttons/ButtonWithLoader';
import { IUser } from '../../../auth/interfaces';
import AlertCompanyPayments from './AlertCompanyPayments';
import { useOrderTableStyles } from '../hooks/useOrderTableStyles';
import CompanyInfo from './CompanyInfo';



interface IProps {
  orders: IOrder | null
//   productEstimate: IProduct | null;
  isVendor: boolean | null
  selectAction: (id: any) => void;
  selectedId: number
  editStatus: (id: any) => void;
  me?: IUser;
  isBuyer?: boolean | null
}

const OrderTable: React.FC<IProps> = ({
    orders,
    // productEstimate,
    isVendor,
    selectedId,
    selectAction,
    editStatus,
    me,
    isBuyer,
}) => {
    const classes = useOrderTableStyles();
    const homeClasses = homeStyles();
    const intl = useIntl();
    const history = useHistory();
    const [isOpenCompanyDialog, setOpenCompanyDialog] = useState(false);
    const [isOpenEstimateDialog, setOpenEstimateDialog] = useState(false);
    const [isOpenPaymentDialog, setOpenPaymentDialog] = useState(false);
    const [isOpenVendorPaymentDialog, setOpenVendorPaymentDialog] = useState(false);
    const [companyAndItems, setCompanyAndItems] = useState<{
        company: ICompany, items: IOrderItem[], id: number }>();
    const [vendorAndItems, setvendorAndItems] = useState<{
        company: ICompany, items: IOrderItem[], id: number }>();
    const [com, setCompany] = useState<ICompany>()
    const [est, setEstimete] = useState<ICompany>()

    const translates: { [type: string]: string } = {
        paid: intl.formatMessage({ id: 'ORDER.TYPE.PAYED' }),
        not_paid: intl.formatMessage({ id: 'ORDER.TYPE.NOT_PAID' }),
      };

    const orderTypeList: { type: string }[] = [
        { type: 'paid' },
        { type: 'not_paid' },
      ];

    const getTotalPriceByOrder = (orderItems: IOrderItem[]) => {
        let totalPrice = 0;
        orderItems.forEach(item => {
            totalPrice += item.product.price * item.count;
        });
        return totalPrice;
    };

    const hendlePayments = useCallback((company: ICompany, items: IOrderItem[], id: number) => {
        setCompanyAndItems({company, items, id})
        setOpenPaymentDialog(true)
    }, []);

    const hendlePaymentsVendor = useCallback((company: ICompany, items: IOrderItem[], id: number) => {
        setvendorAndItems({company, items, id})
        setOpenVendorPaymentDialog(true)
    }, []);

    const openModalCompany = useCallback((company: ICompany) => {
        setCompany(company)
        setOpenCompanyDialog(true)
    }, []);

    const openModalEstimate = useCallback((company: ICompany) => {
        setEstimete(company)
        setOpenEstimateDialog(true)
    }, []);

    const totalPriceByOrder = (orderItems: IOrderItem[]) => {
        let totalOrderPrice = 0;
        orderItems.forEach(item => {
            totalOrderPrice += item.product.price * item.count;
        });
        return totalOrderPrice;
    };

    return (
       <>
        {orders && orders.orders?.map(order => (
            <>
                <CompanyInfo
                    compBrand={order.company.brand}
                    compName={order.company.name}
                    rightTitle='О продавце'
                    onPress={()=> openModalCompany(order.company)}
                />

                {order.items.map((item, index )=> {
                    const totalPrice = getTotalPriceByOrder(order.items);

                    return (
                        <Card className={classes.card} style={{paddingLeft: 0, marginTop: 6, marginBottom: 0,}}>
                            <CardContent className={homeClasses.tableContainer}
                                style={{
                                paddingLeft: 20,
                                paddingTop: 25,
                                }}>
                                <div className={classes.contentWrapper}>
                                <div>
                                    {item.product.photos && item.product.photos.length > 0 ? (
                                    <img
                                        alt=''
                                        src={`${API_DOMAIN}/${
                                        item?.product?.photos?.find((el: any) => el.main)?.small ||
                                        item.product.photos[0].small
                                        }`}
                                        className={classes.img}
                                    />
                                    ) : (
                                    <img
                                        alt=''
                                        src='../../../images/placeholder.png'
                                        className={classes.img}
                                    />
                                    )}
                                </div>

                                <div className={classes.infoWrapper}>
                                    <div className={classes.infoButtonsWrapper}>
                                        <div
                                        style={{
                                            width: 250,
                                            overflow: 'hidden',
                                        }}
                                        >
                                        <Link
                                            to={`${
                                            //   isAdmin
                                            //     ? `/products/edit/${item.product.id}`
                                                // : 
                                                `/viewproduct/${item.product.id}`
                                            }`}
                                            className={classes.link}
                                        >
                                            {item.product.name}
                                        </Link>
                                        </div>

                                        <div style={{width: 200}}>
                                            <Typography
                                                style={{
                                                color: '#000000',
                                                fontSize: 21,
                                                height: 30,
                                                fontWeight: 'normal'
                                                }}
                                            >
                                                {thousands(String(item.product.price))} руб.
                                            </Typography>
                                            <Typography
                                                style={{
                                                color: '#000000',
                                                fontSize: 15,
                                                height: 20,
                                                fontWeight: 'normal'
                                                }}
                                            >
                                                Цена за 1 шт.
                                            </Typography>
                                        </div>

                                        <div  style={{paddingLeft: 10,paddingRight: 10}}>
                                            <Typography
                                                style={{
                                                color: '#40271e',
                                                fontSize: 20,
                                                height: 24,
                                                fontWeight: 'bold',
                                                paddingTop: 3
                                                }}
                                            >
                                                Х {item.count}
                                            </Typography>
                                        </div>

                                        <div  style={{width: 200}}>
                                            <Typography
                                                style={{
                                                color: '#40271e',
                                                fontSize: 21,
                                                height: 30,
                                                fontWeight: 'bold'
                                                }}
                                            >
                                                {thousands(String(+item.product.price * item.count))} руб.
                                            </Typography>
                                            <Typography
                                                style={{
                                                color: '#000000',
                                                fontSize: 15,
                                                height: 32,
                                                fontWeight: 'normal'
                                                }}
                                            >
                                                Сумма
                                            </Typography>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    );
                })}


                <Card className={classes.card} style={{paddingLeft: 0, marginTop: 6}} >
                    <CardContent
                        style={{
                        paddingLeft: 20,
                        paddingTop: 25,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                        }}
                    >
                        <div style={{display: 'flex',flexDirection: 'row', alignItems: 'center'}}>
                        <Typography
                            style={{
                            color: '#757575',
                            fontSize: 21,
                            height: 27,
                            fontWeight: 'normal'
                            }}
                        >
                            ИТОГО ПО ПРОДАВЦУ:
                        </Typography>

                        <Typography
                            style={{
                            color: '#000000',
                            fontSize: 21,
                            height: 27,
                            fontWeight: 'bold',
                            marginLeft: 10
                            }}
                        >
                            {thousands(String(getTotalPriceByOrder(order.items)))} руб.
                        </Typography>
                        </div>

                        <Typography
                            style={{
                            color: '#d01c1f',
                            fontSize: 21,
                            height: 27,
                            fontWeight: 'bold',
                            marginLeft: 10,
                            }}
                        >
                            {translates[order.payment_status]}
                        </Typography>

                        <ButtonWithLoader
                            style={{ background: '#d01c1f' }}
                            onPress={() => hendlePayments(order?.company, order?.items, order.id)}
                        >
                            <Typography
                                style={{
                                    fontSize: 20,
                                    color: '#ffffff',
                                    fontWeight: 'bold'
                                }}
                            >
                                Реквизиты для оплаты
                            </Typography>
                        </ButtonWithLoader>
                    </CardContent>
                </Card>
            </>
        ))}
        <>
        {me?.is_admin && (
            orders && orders.items && orders.items.length > 0 && (
                <CompanyInfo
                compBrand={orders.items[0]?.product?.company?.brand}
                compName={orders.items[0]?.product?.company?.name}
                rightTitle='О продавце'
                onPress={() => {
                    const company = orders.items[0]?.product?.company;
                    if (company) {
                      openModalCompany(company);
                    }
                  }}
                />
            )
        ) }
        {isBuyer && orders?.items && orders?.items.length > 0 && (
            <CompanyInfo
                compBrand={orders?.company?.brand}
                compName={orders?.company?.name}
                rightTitle='О продавце'
                onPress={() => {
                 const company = orders?.company
                 if (company) {
                   openModalCompany(company);
                 }
               }}
            />
        )}


        {orders && orders?.items?.map(order => {
            const totalOrderPrice = totalPriceByOrder(orders.items);
            return(
            <Card className={classes.card} style={{paddingLeft: 0, marginTop: 6, marginBottom: 0,}}>
                <CardContent className={homeClasses.tableContainer}
                    style={{
                    paddingLeft: 20,
                    paddingTop: 25,
                    }}>
                    <div className={classes.contentWrapper}>
                        <div>
                            {order.product.photos && order.product.photos.length > 0 ? (
                            <img
                                alt=''
                                src={`${API_DOMAIN}/${
                                order?.product?.photos?.find((el: any) => el.main)?.small ||
                                order.product.photos[0].small
                                }`}
                                className={classes.img}
                            />
                            ) : (
                            <img
                                alt=''
                                src='../../../images/placeholder.png'
                                className={classes.img}
                            />
                            )}
                        </div>

                        <div className={classes.infoWrapper}>
                            <div className={classes.infoButtonsWrapper}>
                                <div style={{width: 250, overflow: 'hidden'}}>
                                    <Link to={`${`/viewproduct/${order.product.id}`}`}className={classes.link}>
                                        {order.product.name}
                                    </Link>
                                </div>

                                <div style={{width: 200}}>
                                    <Typography
                                        style={{
                                        color: '#000000',
                                        fontSize: 21,
                                        height: 30,
                                        fontWeight: 'normal'
                                        }}
                                    >
                                        {thousands(String(order.product.price))} руб.
                                    </Typography>
                                    <Typography
                                        style={{
                                        color: '#000000',
                                        fontSize: 15,
                                        height: 20,
                                        fontWeight: 'normal'
                                        }}
                                    >
                                        Цена за 1 шт.
                                    </Typography>
                                </div>

                                <div  style={{paddingLeft: 10,paddingRight: 10}}>
                                    <Typography
                                        style={{
                                        color: '#40271e',
                                        fontSize: 20,
                                        height: 24,
                                        fontWeight: 'bold',
                                        paddingTop: 3
                                        }}
                                    >
                                        Х {order.count}
                                    </Typography>
                                </div>

                                <div  style={{width: 200}}>
                                    <Typography
                                        style={{
                                        color: '#40271e',
                                        fontSize: 21,
                                        height: 30,
                                        fontWeight: 'bold'
                                        }}
                                    >
                                        {thousands(String(+order.product.price * order.count))} руб.
                                    </Typography>

                                    <Typography
                                        style={{
                                        color: '#000000',
                                        fontSize: 15,
                                        height: 32,
                                        fontWeight: 'normal'
                                        }}
                                    >
                                        Сумма
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
        )})}

        {isVendor && (
            <Card className={classes.card} style={{paddingLeft: 0, marginTop: 6}} >
                <CardContent
                    style={{
                    paddingLeft: 20,
                    paddingTop: 25,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                    }}>
                    <div style={{display: 'flex',flexDirection: 'row', alignItems: 'center'}}>
                    <Typography
                        style={{
                        color: '#757575',
                        fontSize: 21,
                        height: 27,
                        fontWeight: 'normal'
                        }}
                    >
                        ИТОГО ПО ПРОДАВЦУ:
                    </Typography>

                    <Typography
                        style={{
                        color: '#000000',
                        fontSize: 21,
                        height: 27,
                        fontWeight: 'bold',
                        marginLeft: 10
                        }}
                    >
                   {thousands(String(totalPriceByOrder(orders?.items || [])))} руб.
                    </Typography>
                    </div>

                    <div>
                        {selectedId !== orders?.id ? (
                        <Typography
                            style={{
                            color: '#d01c1f',
                            fontSize: 21,
                            height: 27,
                            fontWeight: 'bold',
                            marginLeft: 10,
                            cursor: me?.is_buyer ? 'none' : 'pointer'
                            }}
                        >
                            <p onClick={() => selectAction(orders?.id)}>{translates[orders?.payment_status || '']}</p>
                        </Typography>
                        ) : (
                        <TextField
                            select
                            margin='none'
                            value={orders?.payment_status}
                            onChange={event => {
                                editStatus({
                                  id: orders.id,
                                  page: 1,
                                  perPage: 20,
                                  data: {
                                    payment_status: event.target.value,
                                    items: orders.items.map(e => ({
                                      count: e.count,
                                      product_id: e.product.id,
                                    })),
                                  },
                                  userId: me?.id,
                                  root: me?.is_admin
                                });
                                selectAction(-1);
                              }}
                            variant='outlined'
                            className={classes.typeList}
                            size='small'
                            >
                            {orderTypeList &&
                                orderTypeList.map(option => (
                                <MenuItem key={option.type} value={option.type}>
                                    {translates[option.type]}
                                </MenuItem>
                                ))}
                            </TextField>
                        )}
                        </div>

                    <ButtonWithLoader
                      style={{ background: '#d01c1f' }}
                      onPress={() => hendlePaymentsVendor(orders?.company!, orders?.items!, orders?.id!)}>
                        <Typography
                          style={{
                            fontSize: 20,
                            color: '#ffffff',
                            fontWeight: 'bold'}}>
                          Реквизиты для оплаты
                        </Typography>
                    </ButtonWithLoader>
                </CardContent>
            </Card>
            )}
            { (((me?.is_admin || isBuyer) && thousands(String(totalPriceByOrder(orders?.items || []))) !== '0') &&
            <Card className={classes.card} style={{paddingLeft: 0, marginTop: 6}} >
                <CardContent
                    style={{
                    paddingLeft: 20,
                    paddingTop: 25,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                    }}>
                    <div style={{display: 'flex',flexDirection: 'row', alignItems: 'center'}}>
                    <Typography
                        style={{
                        color: '#757575',
                        fontSize: 21,
                        height: 27,
                        fontWeight: 'normal'
                        }}
                    >
                        ИТОГО ПО ПРОДАВЦУ:
                    </Typography>

                    <Typography
                        style={{
                        color: '#000000',
                        fontSize: 21,
                        height: 27,
                        fontWeight: 'bold',
                        marginLeft: 10
                        }}
                    >
                   {thousands(String(totalPriceByOrder(orders?.items || [])))} руб.
                    </Typography>
                    </div>

                    <div>
                        {selectedId !== orders?.id ? (
                        <Typography
                            style={{
                            color: '#d01c1f',
                            fontSize: 21,
                            height: 27,
                            fontWeight: 'bold',
                            marginLeft: 10,
                            cursor: me?.is_buyer ? 'none' : 'pointer'
                            }}
                        >
                            <p onClick={() => selectAction(orders?.id)}>{translates[orders?.payment_status || '']}</p>
                        </Typography>
                        ) : (
                        <TextField
                            select
                            margin='none'
                            value={orders?.payment_status}
                            onChange={event => {
                                editStatus({
                                    id: orders.id,
                                    page: 1,
                                    perPage: 20,
                                    data: {
                                      payment_status: event.target.value,
                                      items: orders.items.map(e => ({
                                        count: e.count,
                                        product_id: e.product.id,
                                      })),
                                    },
                                    userId: me?.id,
                                    root: me?.is_admin
                                  });
                                  selectAction(-1);
                                }}
                            variant='outlined'
                            className={classes.typeList}
                            size='small'
                            >
                            {orderTypeList &&
                                orderTypeList.map(option => (
                                <MenuItem key={option.type} value={option.type}>
                                    {translates[option.type]}
                                </MenuItem>
                                ))}
                            </TextField>
                        )}
                        </div>

                    <ButtonWithLoader
                      style={{ background: '#d01c1f' }}
                      onPress={() => hendlePaymentsVendor(orders?.company!, orders?.items!, orders?.id!)}>
                        <Typography
                          style={{
                            fontSize: 20,
                            color: '#ffffff',
                            fontWeight: 'bold'}}>
                          Реквизиты для оплаты
                        </Typography>
                    </ButtonWithLoader>
                </CardContent>
            </Card>
            )}
                <AlertCompanyDetail
                    open={isOpenCompanyDialog}
                    message={intl.formatMessage({ id: 'FILE.IMPORT.ALERT.TEXT' })}
                    company={com}
                    handleClose={() => {
                        setOpenCompanyDialog(false)
                    }
                    }
                />

                <AlertCompanyPayments
                    open={isOpenPaymentDialog}
                    message={intl.formatMessage({ id: 'FILE.IMPORT.ALERT.TEXT' })}
                    company={companyAndItems?.company}
                    prices={companyAndItems?.items}
                    orderId={companyAndItems?.id}
                    handleClose={() => {
                        setOpenPaymentDialog(false);
                    }}
                />

                <AlertCompanyDetail
                    open={isOpenEstimateDialog}
                    message={intl.formatMessage({ id: 'FILE.IMPORT.ALERT.TEXT' })}
                    company={est}
                    handleClose={() => {
                        setOpenEstimateDialog(false)}
                    }
                />

                <AlertCompanyPayments
                    open={isOpenVendorPaymentDialog}
                    message={intl.formatMessage({ id: 'FILE.IMPORT.ALERT.TEXT' })}
                    company={vendorAndItems?.company}
                    prices={vendorAndItems?.items}
                    orderId={vendorAndItems?.id}
                    handleClose={() => {
                        setOpenVendorPaymentDialog(false);
                    }}
                />

                <AlertCompanyPayments
                    open={isOpenPaymentDialog}
                    message={intl.formatMessage({ id: 'FILE.IMPORT.ALERT.TEXT' })}
                    company={companyAndItems?.company}
                    prices={companyAndItems?.items}
                    orderId={companyAndItems?.id}
                    handleClose={() => {
                        setOpenPaymentDialog(false);
                    }}
                />
            </>
        </>
    )
};

export default OrderTable;
