import axios from 'axios';

import { API_SEARCH_LOCATION_URI } from '../constants';

const callLocationApi = axios.create({
  baseURL: `${API_SEARCH_LOCATION_URI}`,
  headers: {
    Accept: '*/*',
  },
});

export const getLocation = (q, locale = 'ru') => {
  const locales = {
    ru: 'ru_RU',
    en: 'en_US',
  };

  return callLocationApi.get(
    `${API_SEARCH_LOCATION_URI}&geocode=${q}&lang=${locales[locale]}`
  );
};

// export const getLocationByCoord = ({ lat, lng }) =>
//   callLocationApi.get(`${API_SEARCH_LOCATION_URI}&latlng=${lat},${lng}`);
export const getLocationByCoord = ({ lat, lng }) =>
  callLocationApi.get(`${API_SEARCH_LOCATION_URI}&geocode=${lat},${lng}`);
