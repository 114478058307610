import { colors } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
  },
  dropZone: {
    border: `2px dashed ${theme.palette.divider}`,
    padding: '50px 0',
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    cursor: 'default',
    [theme.breakpoints.up('sm')]: {
      padding: 50,
    },
  },
  dropZoneActive: {
    '&:hover': {
      backgroundColor: colors.grey[50],
      opacity: 0.8,
      cursor: 'pointer',
    },
  },
  selectedDropZone: {
    borderColor: '#0abb87',
  },
  dragActive: {
    backgroundColor: colors.grey[50],
    opacity: 0.5,
  },
  image: {
    width: 92,
    height: 100,
  },
  info: {
    fontSize: 17,
    lineHeight: 'normal',
    fontWeight: theme.typography.fontWeightRegular,
  },
  list: {
    maxHeight: 320,
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  content: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 35,
    maxWidth: 380,
    width: '100%',
  },
}));
