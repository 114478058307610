import axios from 'axios';
import {
  addProuctToCartUrl,
  checkoutCartUrl,
  clearCartUrl,
  createCartUrl,
  deleteCartUrl,
  getCartUrl,
  setProductCountUrl,
} from '../constants';
import { ICartCheckout } from '../pages/home/cart/interfaces';

export function setProductCount({
  hash,
  product_id,
  count,
}: {
  hash: string;
  product_id: number;
  count: number;
}) {
  return axios.put(setProductCountUrl(hash, product_id, count), {});
}

export function getCart(hash?: string) {
  return axios.get('/api/shop/cart', {
    params: {
      hash,
    },
  });
}
export function deleteCart(hash: string) {
  return axios.delete(deleteCartUrl(hash));
}

export function clearCart(hash: string) {
  return axios.put(clearCartUrl(hash), {});
}

export function createCart() {
  return axios.post(createCartUrl(), {});
}

export function addProduct({
  hash,
  product_id,
  count,
}: {
  hash: string;
  product_id: number;
  count: number;
}) {
  return axios.post(addProuctToCartUrl(hash, product_id, count), {});
}

export function cartCheckout(data: ICartCheckout, hash: string) {
  return axios.post(checkoutCartUrl(hash), {
    address: data.location.address,
    status: 'new',
    fio: data.fio,
    phone: data.phone,
    email: data.email,
    useBonuse: data.useBonuse,
    deliveryPointId: data.deliveryPointId,
    city: data.city,
    flat: data.flat,
    region: data.region,
    home: data.home,
    street: data.street,
    receivedAtString: data.received_at,
    deliveryType: data.deliveryType,
  });
}

export function cartEdit(
  fio: string,
  email: string,
  phone: string,
  hash: string,
  receivedAtString?: string
) {
  return axios.put(getCartUrl(hash), {
    fio: fio || undefined,
    email: email || undefined,
    phone: phone ? phone.replace(`+`, '').replace(/ /g, '').trim() : undefined,
    receivedAtString,
  });
}
