import { createStyles, makeStyles } from '@material-ui/core';

export const useStylesReviewsForm = makeStyles(theme =>
  createStyles({
    reviews: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      [theme.breakpoints.down(500)]: {
        flexDirection: 'column-reverse',
        padding: '0 16px',
      },
    },
    reviewsList: {
      width: '100%',
      [theme.breakpoints.down(500)]: {
        marginTop: 24,
        width: '100%',
        paddingRight: 0,
      },
    },
    description: {
      fontSize: 17,
      fontWeight: 400,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '23px',
      letterSpacing: 'normal',
      whiteSpace: 'break-spaces',
      marginBottom: 13,
    },
    btn: {
      minWidth: 240,
    },
    btnText: {
      fontSize: 17,
      fontWeight: 'bold',
      textTransform: 'initial',
    },
    statistics: {
      width: '30%',
      [theme.breakpoints.down(500)]: {
        width: '100%',
      },
    },
    statisticsHeader: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between',

      marginBottom: 22,
      [theme.breakpoints.down(500)]: {
        alignItems: 'center',

        '& p': {
          fontSize: 23,
        },
      },
    },
    headerTitle: {
      fontSize: 20,
      padding: 0,
      margin: '30px 0 14px',
      fontWeight: 'bold',
      [theme.breakpoints.down(500)]: {
        fontSize: '23px !important',
        margin: '24px 0 !important',
        padding: '0 16px',
      },
    },
    avatar: {
      width: 50,
      height: 50,

      marginRight: 15,

      '& img': {
        borderRadius: '50%',
        width: '100%',
        objectFit: 'contain',
        height: '100%',
      },
    },
    feedButton: {
      margin: '16px 0',
    },
    statisticsList: {
      marginBottom: 8,
    },
    statisticsItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 8,
      whiteSpace: 'nowrap',
      '& .progress': {
        /* Primary colors/Green */
        background: 'red',
        borderRadius: 12,
        height: 5,
        flex: 1,
        overflow: 'hidden',
        margin: '0 16px',
        '& span': {
          display: 'inline-block',
          height: '100%',
          background: 'red',
        },
      },
      [theme.breakpoints.down(500)]: {
        '& p:first-child': {
          marginRight: `16px !important`,
          color: 'red',
        },
        '& p:last-child': {
          marginLeft: `16px !important`,
          color: 'red',
        },
      },
    },
    actions: {
      paddingTop: 16,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    review: {
      marginBottom: 25,
    },
    containerReview: {
      marginBottom: 3,
      display: 'flex',
      alignItems: 'center',
    },
    reviewUser: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: 'auto',
    },
    reviewInfo: {
      marginLeft: 65,
    },
  })
);
