import { LayoutFooter, LayoutSubheader } from '../../_metronic';
import { IBreadcrumb } from '../interfaces/breadcrumb';

interface IProps {
  title?: string;
  page?: string;
  breadcrumb?: IBreadcrumb[] | undefined;
  description?: string;
  back?: boolean;
  show?: boolean;
  rightTitle?: string;
  onPress?: () => void
  orderCounts?: string
  subTitle?: string
  items?: any
  orders?: any[]
  orderTitle?: string
  ordersId?: any[]
}

export const setLayoutSubheader = ({
  title = '',
  page = '',
  breadcrumb = [],
  description = '',
  back,
  rightTitle,
  onPress,
  orderCounts,
  subTitle,
  items = [],
  orders = [],
  orderTitle,
  ordersId
}: IProps) => {
  LayoutSubheader({ 
    title, 
    page, 
    breadcrumb, 
    description, 
    back, 
    rightTitle, 
    onPress, 
    orderCounts, 
    subTitle,
    items,
    orders,
    orderTitle,
    ordersId
  });
};
export const setLayoutFooter = ({ show = true }: IProps) => {
  return LayoutFooter({ show });
};
