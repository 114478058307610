import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Link as RouterLink, useHistory, RouteComponentProps, Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { Skeleton } from '@material-ui/lab';
import {
  TextField,
  Button,
  MenuItem,
  Card,
  Tab,
  Tabs,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import { IAppState } from '../../../store/rootDuck';
import { actions as orderActions } from '../../../store/ducks/orders.duck';
import { IOrder } from '../../../interfaces/order';
import homeStyles from '../../../constants/homeStyles';
import ButtonWithLoader from '../../../components/other/Buttons/ButtonWithLoader';
import { setLayoutSubheader } from '../../../utils/layout';
import { getOrderTypeWithTranslates, getStatusTypeWithTranslates } from './constatns';
import { useStyles } from './hooks/useStyles';
import AlertDialog from '../../../components/other/Dialog/AlertDialog';
import { TabPanel } from '../../../components/other/Tab/TabPanel';
import { SHeadTableCell } from '../../../components/styledComponents/Tables';
import { API_DOMAIN } from '../../../constants';
import { thousands } from '../../../utils/utils';
import AutocompleteLocations from '../../../components/AutocompleteLocations';
import { actions as locationActions } from '../../../store/ducks/yaLocations.duck';
import { IStore } from '../../../interfaces/store';
import { ILocation } from '../../../interfaces/locations';
import { useDefineUserRole } from '../../../hooks';
import OrderTable from './components/OrderTalbe';

const locales: { [key: string]: any } = {
  ru: ruLocale,
  en: enLocale,
};
const getInitialValues = (order: IOrder) => {
  const values: any = {
    fio: order.main_order ? order.main_order.fio || '' : order.fio || '',
    city: order.city || '',
    region: order.region || '',
    street: order.street || '',
    home: order.home || '',
    flat: order.flat || '',
    entrance: order.entrance || '',
    building: order.building || '',
    phone: order.main_order ? order.main_order.phone || '' : order.phone || '',
    comment: order.comment || '',
    status: order.status || 'payed',
    email: order.email || '',
    payment_status: order.payment_status || 'not_paid',
    address: order.address || '',
    deliveryType: order.delivery_type || '-',
    receivedAtString: order.received_at,
    items: [],
  };
  if (order.main_order) {
    order.items.forEach(item => {
      values.items.push({ ...item, product_id: item?.product.id! });
    });
  } else {
    order.orders &&
      order.orders.forEach(item => {
        item.items.map(e => values.items.push({ ...e, product_id: e?.product.id! }));
      });
  }
  return values;
};

export enum ViewMode {
  VIEW = 'view',
  EDIT = 'edit',
}
enum DeliveryType {
  self = 'self',
  delivery = 'delivery',
}
const OrderPage: React.FC<
  TPropsFromRedux & RouteComponentProps<{ id: string; mode: string }>
> = ({
  match: {
    params: { id, mode },
  },
  order,
  loading,
  editLoading,
  fetchById,
  edit,
  editError,
  editSuccess,
  clear,
  loadingLocations,
  locations,
  byCoordsLoading,
  fetchLocations,
  clearLocations,
  stores,
  editStatus,
}) => {
  const [isAlertOpen, setAlertOpen] = useState(false);
  const intl = useIntl();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const homeClasses = homeStyles();
  const classes = useStyles();
  const [valueTabs, setValueTabs] = useState(0);
  const [pickupLocation, setPickupLocation] = useState<IStore | null>(null);
  const [shippingLocation, setShippingLocation] = useState<ILocation | null>(null);
  const [selectedId, setSelectedId] = useState(-1);
  const { me } = useSelector(({ profile }: any) => profile);
  const handleTabsChange = (event: any, newValue: number) => {
    setValueTabs(newValue);
  };
  const disabled = mode === ViewMode.VIEW;
  const isRoleAdmin = useDefineUserRole(me, 'ROLE_ADMIN');
  const isVendor = useDefineUserRole(me, 'ROLE_VENDOR');
  const isBuyer = useDefineUserRole(me, 'ROLE_BUYER');
  const filterPickupStores = useMemo(() => {
    const newStores = stores.reduce<IStore[]>((accumulator, item) => {
      Boolean(item.location?.pickup_point) && accumulator.push(item);
      return accumulator;
    }, []);
    return newStores;
  }, [stores, stores.length]);

  useEffect(() => {
    if (id) {
      fetchById(+id);
    }
  }, [id, fetchById]);

  const itemsIds: number[] = [];
  const ordersIds: number[] = [];

  order?.items?.forEach(item => {
    itemsIds.push(item.id);
  });
  order?.orders?.forEach(item => {
    ordersIds.push(item.id);
  });

  useEffect(() => {
    if (editSuccess || editError) {
      enqueueSnackbar(
        editSuccess
          ? ` ${intl.formatMessage({
              id: 'ORDER.SNACKBAR.EDIT',
            })}`
          : `${intl.formatMessage({ id: 'PRODUCT.SNACKBAR.ERROR' })} ${editError}`,
        { variant: editSuccess ? 'success' : 'error' }
      );
      if (editSuccess) {
        history.goBack();
      }
    }
  }, [editSuccess, editError, enqueueSnackbar, id, intl, history]);

  const {
    values,
    handleBlur,
    handleChange,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    setValues,
    isValid,
  } = useFormik({
    initialValues: order ? getInitialValues(order) : {},
    validationSchema: Yup.object().shape({
      phone: Yup.string()
        .required(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' }))
        .nullable(),
      fio: Yup.string()
        .required(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' }))
        .nullable(),
    }),
    onSubmit: submitValues => {
      edit({ id: +id, data: submitValues });
    },
  });

  useEffect(() => {
    if (order) {
      setValues(getInitialValues(order));
    }
  }, [order, setValues]);

  const translates: { [type: string]: string } = {
    cart: intl.formatMessage({ id: 'ORDER.TYPE.CART' }),
    new: intl.formatMessage({ id: 'ORDER.TYPE.NEW' }),
    formation: intl.formatMessage({ id: 'ORDER.TYPE.FORMATION' }),
    ready_to_delivery: intl.formatMessage({ id: 'ORDER.TYPE.DELIVERY' }),
    // payed: intl.formatMessage({ id: 'ORDER.TYPE.PAYED' }),
    canceled: intl.formatMessage({ id: 'ORDER.TYPE.CANCELED' }),
    completed: intl.formatMessage({ id: 'ORDER.TYPE.COMPLETED' }),
    in_processing: intl.formatMessage({ id: 'ORDER.TYPE.PROCCESS' }),
  };
  const translatesStatus: { [type: string]: string } = {
    paid: intl.formatMessage({ id: 'ORDER.TYPE.PAYED' }),
    not_paid: intl.formatMessage({ id: 'ORDER.TYPE.NOT_PAID' }),
  };
  const orderTypes = getOrderTypeWithTranslates(translates, true);
  const orderStatusTypes = getStatusTypeWithTranslates(translatesStatus, true);
  const mappedOrders = order?.main_order?.orders_id.map(id => ({ id })) || [];

  if (isRoleAdmin) {
    setLayoutSubheader({
      rightTitle:
        mode === ViewMode.VIEW || (order && order.orders && order.orders.length > 0)
          ? intl.formatMessage({ id: 'ORDER.HEADER.VIEW.EDIT' })
          : '',
      title:
        mode === ViewMode.VIEW
          ? `${intl.formatMessage({ id: 'ORDER.HEADER.VIEW' })} № ${
              order?.main_order ? order.main_order.id : order?.id || ''
            }, ${intl.formatMessage({ id: 'ORDER.HEADER.VIEW.TITLE' })}`
          : `${intl.formatMessage({ id: 'ORDER.HEADER.EDIT' })}`,
      onPress() {
        const newMode = mode === ViewMode.VIEW ? ViewMode.EDIT : ViewMode.VIEW;
        history.push(`/orders/${id}/${newMode}`);
      },
      subTitle: order?.main_order ? 'Просмотр подзаказа №' : '',
      orders: order?.orders ? order?.orders : [],
      items: order,
      ordersId: mappedOrders,
    });
  } else {
    setLayoutSubheader({
      title:
        mode === ViewMode.VIEW
          ? `${intl.formatMessage({ id: 'ORDER.HEADER.VIEW' })}`
          : `${intl.formatMessage({ id: 'ORDER.HEADER.EDIT' })}`,
    });
  }

  const submitCallback = useCallback(() => {
    if (
      order &&
      (order.status === 'payed' ||
        order.status === 'completed' ||
        order.status === 'ready_to_delivery')
    ) {
      setAlertOpen(true);
    } else {
      handleSubmit();
    }
  }, [order, handleSubmit]);

  useEffect(
    () => () => {
      clear();
    },
    [clear]
  );

  const selectAction = useCallback(
    id => {
      setSelectedId(id);
    },
    [setSelectedId]
  );

  const alertText = `${intl.formatMessage({ id: 'ORDER.ALERT.EDIT.TEXT1' })} "${
    translates[order?.status || '']
  }". ${intl.formatMessage({ id: 'ORDER.ALERT.EDIT.TEXT2' })}`;

  if (loading) {
    return <></>;
  }

  return (
    <>
      <AlertDialog
        open={isAlertOpen}
        message={alertText}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          setAlertOpen(false);
        }}
        handleAgree={() => handleSubmit()}
      />
      <Card className={homeClasses.container}>
        <Tabs
          value={valueTabs}
          onChange={handleTabsChange}
          variant='scrollable'
          indicatorColor='primary'
          textColor='primary'
          aria-label='tabs'
        >
          <Tab label={intl.formatMessage({ id: 'ORDER.VIEW.TABS.FIELDS' })} />
          <Tab label={intl.formatMessage({ id: 'ORDER.VIEW.TABS.ITEMS' })} />
        </Tabs>
        <div className={homeClasses.table}>
          <TabPanel value={valueTabs} index={0}>
            {loading ? (
              Array.from(Array(5)).map((_, idx) => (
                <Skeleton
                  key={idx}
                  width='100%'
                  height={86}
                  animation='wave'
                  style={{ marginTop: -5, marginBottom: -5 }}
                />
              ))
            ) : (
              <>
                <TextField
                  type='text'
                  label={intl.formatMessage({ id: 'ORDER.INPUT.NAME' })}
                  margin='normal'
                  name='fio'
                  value={values.fio}
                  variant='outlined'
                  helperText={touched.fio && errors.fio}
                  error={Boolean(touched.fio && errors.fio)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={disabled}
                />
                <TextField
                  type='text'
                  label={intl.formatMessage({ id: 'ORDER.INPUT.PHONE' })}
                  margin='normal'
                  name='phone'
                  value={values.phone}
                  variant='outlined'
                  helperText={touched.phone && errors.phone}
                  error={Boolean(touched.phone && errors.phone)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={disabled}
                />
                <TextField
                  type='email'
                  label={intl.formatMessage({ id: 'USER.EDIT.EMAIL' })}
                  margin='normal'
                  name='email'
                  value={values.email}
                  variant='outlined'
                  helperText={touched.email && errors.email}
                  error={Boolean(touched.email && errors.email)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={disabled}
                />
                <TextField
                  select
                  margin='normal'
                  label='Вид доставки'
                  value={values.deliveryType || 'delivery'}
                  onChange={handleChange}
                  name='deliveryType'
                  variant='outlined'
                  helperText={touched.deliveryType && errors.deliveryType}
                  error={Boolean(touched.deliveryType && errors.deliveryType)}
                  disabled={disabled}
                >
                  <MenuItem value='delivery'>
                    {intl.formatMessage({ id: 'SUBMENU.SHIPPING' })}
                  </MenuItem>
                  <MenuItem value='self'>
                    {intl.formatMessage({ id: 'CART.DELIVERY_TYPE.SELF' })}
                  </MenuItem>
                </TextField>

                <MuiPickersUtilsProvider locale={locales[intl.locale]} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    label={intl.formatMessage({ id: 'ORDER.DELIVERY.DATE' })}
                    margin='normal'
                    name='received_at'
                    format='dd MMMM'
                    value={values.receivedAtString}
                    helperText={touched.receivedAtString && errors.receivedAtString}
                    error={Boolean(touched.receivedAtString && errors.receivedAtString)}
                    onBlur={handleBlur}
                    onChange={date => {
                      setFieldValue('receivedAtString', date);
                    }}
                    minDate={Date.now()}
                    inputVariant='outlined'
                    disabled={disabled}
                  />
                </MuiPickersUtilsProvider>
                {/* {values.deliveryType === 'delivery' ? ( */}
                <AutocompleteLocations
                  options={locations || []}
                  loading={loadingLocations || byCoordsLoading}
                  // @ts-ignore
                  inputValue={{ address: values.address }}
                  editable
                  label={intl.formatMessage({
                    id: 'STORE.INPUT.LOCATION',
                  })}
                  inputClassName={{ width: '100%' }}
                  inputError={Boolean(touched.address && errors.address && errors.address)}
                  inputHelperText={touched.address && errors.address && errors.address}
                  fetchLocations={fetchLocations}
                  clearLocations={clearLocations}
                  setSelectedLocation={location => {
                    setShippingLocation(location || null);
                    location
                      ? setFieldValue('address', location.address)
                      : setFieldValue('address', '');
                  }}
                  handleBlur={handleBlur}
                  disable={disabled}
                />
                {/* ) : ( */}
                {/*  <TextField */}
                {/*    disabled={disabled} */}
                {/*    select */}
                {/*    type='address' */}
                {/*    label={intl.formatMessage({ */}
                {/*      id: 'STORE.INPUT.LOCATION', */}
                {/*    })} */}
                {/*    margin='normal' */}
                {/*    className='kt-width-full' */}
                {/*    variant='outlined' */}
                {/*    value={order?.address || undefined} */}
                {/*    onChange={e => { */}
                {/*      const store = filterPickupStores.find( */}
                {/*        store => store.id === +e.target.value */}
                {/*      ); */}
                {/*      if (store) { */}
                {/*        setPickupLocation(store); */}
                {/*        setFieldValue('address', store.location && store.location.address); */}
                {/*      } */}
                {/*    }} */}
                {/*    error={Boolean(touched.address && errors.address && errors.address)} */}
                {/*    helperText={touched.address && errors.address && errors.address} */}
                {/*  > */}
                {/*    {filterPickupStores.map(item => ( */}
                {/*      <MenuItem key={item.id} value={item?.location?.address || 0}> */}
                {/*        {item?.location?.address || 'No address'} */}
                {/*      </MenuItem> */}
                {/*    ))} */}
                {/*  </TextField> */}
                {/* )} */}
                <TextField
                  select
                  margin='normal'
                  label={`${intl.formatMessage({ id: 'ORDER.INPUT.STATUS' })}`}
                  value={values.status || ''}
                  onChange={handleChange}
                  name='status'
                  variant='outlined'
                  helperText={touched.status && errors.status}
                  error={Boolean(touched.status && errors.status)}
                  disabled={disabled}
                >
                  {orderTypes.map(item => (
                    <MenuItem value={item.type}>{item.translate}</MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  margin='normal'
                  label={`${intl.formatMessage({ id: 'ORDER.TABLE.STATUS.PAYMENT' })}`}
                  value={values.payment_status || ''}
                  onChange={handleChange}
                  name='payment_status'
                  variant='outlined'
                  helperText={touched.payment_status && errors.payment_status}
                  error={Boolean(touched.payment_status && errors.payment_status)}
                  disabled={disabled}
                >
                  {orderStatusTypes.map(item => (
                    <MenuItem value={item.type}>{item.translate}</MenuItem>
                  ))}
                </TextField>
                {!disabled && (
                  <div className={classes.bottomActions}>
                    <Button
                      onClick={() => history.goBack()}
                      className={classes.buttons}
                      variant='outlined'
                      color='primary'
                    >
                      {intl.formatMessage({ id: 'CATEGORY.BUTTON.CANCEL' })}
                    </Button>
                    <ButtonWithLoader
                      // disabled={editLoading || !isValid}
                      disabled={editLoading}
                      loading={editLoading}
                      onPress={submitCallback}
                    >
                      {intl.formatMessage({ id: 'COMMON.BUTTON.SAVE' })}
                    </ButtonWithLoader>
                  </div>
                )}
              </>
            )}
          </TabPanel>
          <TabPanel value={valueTabs} index={1}>
            <OrderTable
              orders={order || values}
              isVendor={isVendor}
              selectAction={selectAction}
              selectedId={selectedId}
              editStatus={editStatus}
              me={me}
              isBuyer={isBuyer}
            />
            {/* <CardContent className={homeClasses.tableContainer}>
              {values.items ? (
                <>
                  <Table aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <SHeadTableCell>
                          Заказ
                        </SHeadTableCell>
                        <SHeadTableCell>
                          {intl.formatMessage({ id: 'PRODUCT.TABLE.PREVIEW' })}
                        </SHeadTableCell>
                        <SHeadTableCell>
                          {intl.formatMessage({ id: 'PRODUCT.TABLE.NAME' })}
                        </SHeadTableCell>
                        <SHeadTableCell>
                          {intl.formatMessage({ id: 'PRODUCT.TABLE.PRICE' })}
                        </SHeadTableCell>
                        <SHeadTableCell>
                          {intl.formatMessage({ id: 'PRODUCT.TABLE.COUNT' })}
                        </SHeadTableCell>
                        <SHeadTableCell>
                          Статус
                        </SHeadTableCell>
                        <SHeadTableCell>
                          {intl.formatMessage({ id: 'MENU.COMPANY' })}
                        </SHeadTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {values.items.map((item: any) => (
                        <TableRow key={item.product.id}>
                          <TableCell>
                            <RouterLink
                              to={`/products/list/U${item.product.company.id}/${item.product.company.id}`}
                              className={classes.buttons}
                            >
                              {item.id}
                            </RouterLink>
                          </TableCell>
                          <TableCell>
                            {item.product?.photos && item.product.photos.length > 0 && (
                              <RouterLink to={`/viewproduct/${item.product.id}`}>
                                <img
                                  src={`${API_DOMAIN}/${
                                    item.product?.photos?.find((el: any) => el.main)?.small ||
                                    item.product?.photos[0].path
                                  }`}
                                  className={classes.img}
                                  alt={item.product.name}
                                />
                              </RouterLink>
                            )}
                          </TableCell>
                          <TableCell style={{maxWidth: 200}}>
                            <RouterLink to={`/viewproduct/${item.product.id}`}>
                              {item.product.name}
                            </RouterLink>
                          </TableCell>
                          <TableCell>
                            {item.product.price
                              ? thousands(String(item.product.price)) + '₽'
                              : '-'}
                          </TableCell>
                          <TableCell>
                            {item.product.price ? thousands(String(item?.count)) + ' шт' : '-'}
                          </TableCell>
                          <TableCell>
                            {translates[order?.status || '']}
                          </TableCell>
                          <TableCell>
                            <RouterLink
                              to={`/products/list/U${item.product.company.id}/${item.product.company.id}`}
                              className={classes.buttons}
                            >
                              {item.product.company?.name}
                            </RouterLink>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Typography className={classes.empty} component='h5' variant='h5'>
                    {intl.formatMessage({ id: 'ORDER.TABLE.SUM_ORDER' }) +
                      ': ' +
                      thousands(String(order?.summ)) +
                      '₽'}
                  </Typography>
                </>
              ) : (
                ''
              )}
            </CardContent> */}
          </TabPanel>
        </div>
      </Card>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    order: state.orders.order,
    loading: state.orders.byIdLoading,
    editError: state.orders.editError,
    editSuccess: state.orders.editSuccess,
    editLoading: state.orders.editLoading,
    loadingLocations: state.yaLocations.loading,
    locations: state.yaLocations.yaLocations,
    byCoordsLoading: state.yaLocations.getLoacationByCoordsLoading,
    stores: state.cart.stores,
  }),
  {
    fetchById: orderActions.fetchByIdRequest,
    edit: orderActions.editRequest,
    clear: orderActions.clearEdit,
    fetchLocations: locationActions.fetchRequest,
    clearLocations: locationActions.clear,
    editStatus: orderActions.editStatusRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(OrderPage);
