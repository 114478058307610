/* eslint-disable no-plusplus, prefer-const */
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { connect, ConnectedProps, shallowEqual, useSelector } from 'react-redux';
import { Card, Typography } from '@material-ui/core';

import { ImageGallery } from './components';

import { IAppState } from '../../../store/rootDuck';
import { setLayoutSubheader } from '../../../utils/layout';
import { actions as productActions } from '../../../store/ducks/product.duck';
import Preloader from '../../../components/ui/Preloader/Preloader';
import { actions as cartTypesActions } from '../../../store/ducks/cart.duck';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import { maskPhone, formatAsThousands } from '../../../utils/utils';
import { useStylesProducView } from './hooks/useStylesProductView';
import { useDefineUserRole, useFormatMessage } from '../../../hooks';
import ReviewsForm from './components/ReviewForm';
import Rating from './components/Rating';
import { Button } from '../../../components/ui/Buttons';
import ModalReview from './components/ModalReview';

const ProductView: React.FC<RouteComponentProps<{ id: string }> & TPropsFromRedux> = ({
  match: {
    params: { id },
  },
  product,
  loading,
  // isAuthorized,
  // guestCart,
  // cart,
  // isAdmin,
  me,
  loadingMe,
  // editLoading,
  // cartCompanyId,
  // companyGuestCart,
  // countProductsGuest,
  // addProductLoading,
  fetch,
  clearProduct,
  // setCountGuestCart,
  // setProductCount,
  // setProductCountLoading,
  fetchMe,
  clearMe,
  // addProductToCart,
  // setProductGuestCart,
  meAdd,
}) => {
  const intl = useIntl();
  const classes = useStylesProducView();
  const fm = useFormatMessage();
  const history = useHistory();
  const reviewsProduct = useSelector(
    ({ products: { reviewsProduct } }: IAppState) => reviewsProduct,
    shallowEqual
  );
  const [visibleModalReviews, setVisibleModalReviews] = useState(false);
  const isAdmin = useDefineUserRole(me, 'ROLE_ADMIN');
  const isBuyer = useDefineUserRole(me, 'ROLE_BUYER');

  // const [isCartAlertOpen, setCartAlertOpen] = useState<boolean>(false);
  // const [changeFavorite, loadingChange, successChange] = useChangeFavorite();

  setLayoutSubheader({
    title: product?.name || '',
    breadcrumb: [
      {
        title: fm('MENU.PRODUCTS.CATALOG'),
        root: true,
        page: 'products/catalog',
        translate: 'MENU.PRODUCTS.CATALOG',
      },
    ],
    back: true,
  });

  useEffect(() => {
    fetch(Number(id));
    return () => {
      clearProduct();
    };
  }, [id]);

  // useLayoutEffect(() => {
  //   successChange && fetch(Number(id));
  // }, [successChange]);

  // handle add to cart
  // const [cartProductId, setCartProductId] = useState<IProduct | null>(null);

  useEffect(() => {
    fetchMe();
    return () => {
      clearMe();
    };
  }, []);

  const onePiece = Number(product?.price) / (product?.units ?? 1);

  const characteristics = useMemo(() => {
    if (product) {
      const result: { [key: string]: any } = {
        [intl.formatMessage({ id: 'PRODUCT.VIEW.CATEGORY' })]:
          product.category?.name || intl.formatMessage({ id: 'COMMON.NO_DATA' }),
      };

      product.parameter_values?.forEach(item => {
        if (item.parameter_id && item.parameter_name) {
          // запрос не возвращает название параметра
          result[item.parameter_name] = item.value;
        }
      });
      return result;
    }
    return {};
  }, [product]);

  const isCompanyInfo = useMemo(() => {
    return (
      product?.company?.brand ||
      // product.company?.address ||
      product?.company?.phone_number ||
      product?.company?.site
      // || product.company?.working_hours
    );
  }, [product?.company]);

  const companyInfo: { [key: string]: any } | null = isCompanyInfo
    ? {
        [intl.formatMessage({ id: 'PRODUCT.VIEW.COMPANY' })]: product?.company?.brand || null,
        // Адрес: product.company?.address || null,
        [intl.formatMessage({ id: 'PRODUCT.VIEW.PHONE_NUMBER' })]:
          maskPhone(product?.company?.phone_number) || null,
        [intl.formatMessage({ id: 'PRODUCT.VIEW.SITE' })]: product?.company?.site || null,
        // 'Часы работы': product.company?.working_hours
        //   ? product.company?.working_hours.replace(/(\r\n|\n|\r)/gm, '<br>')
        //   : null,
      }
    : null;

  // let productCount = useMemo(() => {
  //   if (!isAuthorized && product && guestCart) {
  //     const item = guestCart.items.find(item => item.product.id === product.id);
  //     if (item) {
  //       return item.count;
  //     }
  //   } else if (Boolean(isAuthorized) && product && cart) {
  //     const item = cart.items.find(item => item.product.id === product.id);
  //     if (item) {
  //       return item.count;
  //     }
  //   }
  //   return null;
  // }, [isAuthorized, product, guestCart?.goods_num, cart?.goods_num, guestCart, cart]);

  // const isVendor = useMemo(() => me && me.roles.includes(UserRoles.ROLE_VENDOR), [me]);

  // const handleCartDialog = useCallback(
  //   item => {
  //     if (isAuthorized) {
  //       const newCart = cartCompanyId ? item?.company?.id !== cartCompanyId : false;
  //       if (!newCart) {
  //         addProductToCart({
  //           product_id: item.id ? item?.id : 0,
  //           count: 1,
  //         });
  //       } else {
  //         setCartProductId(item);
  //         setCartAlertOpen(true);
  //       }
  //     } else {
  //       const newCart = companyGuestCart ? item?.company?.id !== companyGuestCart : false;
  //       if (!newCart) {
  //         setProductGuestCart({ data: item, type: 'cart' });
  //       } else {
  //         if (countProductsGuest === 0) {
  //           setProductGuestCart({ data: item, type: 'new' });
  //         } else {
  //           setCartProductId(item);
  //           setCartAlertOpen(true);
  //         }
  //         console.log('todo');
  //       }
  //     }
  //   },
  //   [addProductToCart, cartCompanyId, companyGuestCart, setProductGuestCart]
  // );

  // const addCartProductAction = useCallback(() => {
  //   if (!addProductLoading && cartProductId) {
  //     addProductToCart({
  //       product_id: cartProductId.id!,
  //       count: 1,
  //       newCart: true,
  //     });
  //     setCartAlertOpen(false);
  //   }
  // }, [cartProductId, addProductToCart]);

  // const addProductGuestCart = useCallback(() => {
  //   if (cartProductId) {
  //     setProductGuestCart({ data: cartProductId, type: 'new' });
  //     setCartAlertOpen(false);
  //   }
  // }, [cartProductId, setProductGuestCart]);
  if (loading || loadingMe || !product) return <Preloader />;
  return (
    <>
      {/* <AlertDialog
        open={isCartAlertOpen}
        message={intl.formatMessage({ id: 'CART.ALERT.TEXT' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          setCartAlertOpen(false);
        }}
        handleAgree={() => (isAuthorized ? addCartProductAction() : addProductGuestCart())}
      /> */}

      <div className={classes.container}>
        <Card className={classes.card}>
          <div className={classes.leftCol}>
            <ImageGallery product={product} />
            <div style={{ marginTop: '50px' }}>
              {product.description && (
                <p
                  className={classes.description}
                  dangerouslySetInnerHTML={{ __html: product.description }}
                />
              )}
            </div>
            <div style={{}} />
          </div>
          <div className={classes.rightCol}>
            <div className={classes.header}>
              <div>
                <p className={classes.name}>
                  <b>{product.name}</b>
                </p>

                <p className={classes.price}>{formatAsThousands(product.price)} руб.</p>

                <p className={classes.listKey}>
                  <b>{product.units || ''} {fm('TH')}.{' '}({onePiece.toFixed(0)} руб. {fm('FOR')} 1 {fm('TH')}.)</b>
                </p>

              </div>

              {/* <Formik */}
              {/*  initialValues={{ test: 'test' }} */}
              {/*  onSubmit={() => handleCartDialog(product)} */}
              {/* > */}
              {/*  {({ handleSubmit }) => ( */}
              {/*    <form onSubmit={handleSubmit}> */}
              {/*      {me && ( */}
              {/*        <ButtonWithLoader */}
              {/*          style={{ margin: '0 10px' }} */}
              {/*          disabled={loadingChange} */}
              {/*          loading={loadingChange} */}
              {/*          onPress={() => changeFavorite(product.id, product.favorite)} */}
              {/*        > */}
              {/*          {intl.formatMessage({ */}
              {/*            id: product.favorite */}
              {/*              ? 'PRODUCT.DELETE.FAVORITE' */}
              {/*              : 'PRODUCT.ADD.FAVORITE', */}
              {/*          })} */}
              {/*        </ButtonWithLoader> */}
              {/*      )} */}
              {/*      {!productCount */}
              {/*        ? !isAdmin && */}
              {/*        !isVendor && ( */}
              {/*          <ButtonWithLoader disabled={editLoading} loading={editLoading}> */}
              {/*            {intl.formatMessage({ id: 'PRODUCT.BUTTON.ADD_CART' })} */}
              {/*          </ButtonWithLoader> */}
              {/*        ) */}
              {/*        : product && */}
              {/*        Boolean(productCount) && ( */}
              {/*          <ButtonGroup */}
              {/*            color='primary' */}
              {/*            aria-label='outlined primary button group' */}
              {/*            variant='contained' */}
              {/*          > */}
              {/*            <Button */}
              {/*              disabled={setProductCountLoading} */}
              {/*              onClick={() => */}
              {/*                isAuthorized */}
              {/*                  ? setProductCount({ */}
              {/*                    product_id: product.id || 0, */}
              {/*                    count: --productCount!, */}
              {/*                  }) */}
              {/*                  : setCountGuestCart({ */}
              {/*                    product_id: product.id || 0, */}
              {/*                    count: --productCount!, */}
              {/*                    type: 'dec', */}
              {/*                  }) */}
              {/*              } */}
              {/*            > */}
              {/*              <RemoveIcon fontSize='small' /> */}
              {/*            </Button> */}
              {/*            <Button */}
              {/*              style={{ */}
              {/*                pointerEvents: 'none', */}
              {/*              }} */}
              {/*            > */}
              {/*              {productCount} */}
              {/*            </Button> */}
              {/*            <Button */}
              {/*              disabled={setProductCountLoading} */}
              {/*              onClick={() => */}
              {/*                isAuthorized */}
              {/*                  ? setProductCount({ */}
              {/*                    product_id: product.id || 0, */}
              {/*                    count: ++productCount!, */}
              {/*                  }) */}
              {/*                  : setCountGuestCart({ */}
              {/*                    product_id: product.id || 0, */}
              {/*                    count: ++productCount!, */}
              {/*                    type: 'inc', */}
              {/*                  }) */}
              {/*              } */}
              {/*            > */}
              {/*              <AddIcon fontSize='small' /> */}
              {/*            </Button> */}
              {/*          </ButtonGroup> */}
              {/*        )} */}
              {/*    </form> */}
              {/*  )} */}
              {/* </Formik> */}
            </div>
            {Object.keys(characteristics).map(key => {
              let valueToDisplay = characteristics[key];

              if (characteristics[key]) {
                if (key === 'Размер' && typeof characteristics[key] === 'string') {
                  valueToDisplay = characteristics[key]
                      .split(',')
                      .map((el: string, index: number) =>
                          index !== characteristics[key].split(',').length - 1 ? el + ', ' : el
                      );
                } else if ( Array.isArray(characteristics[key])) {
                  valueToDisplay = characteristics[key].join(', ');
                }

                return (
                    <div key={key} className={classes.listItem}>
                      <p className={classes.listValue}>{valueToDisplay}</p>
                      <p className={classes.listKey}>{key}</p>
                    </div>
                );
              }

              return null;
            })}

            {companyInfo && (
              <div className={classes.companyCard}>
                {Object.keys(companyInfo).map(key => {
                  return (
                    <>
                      {companyInfo[key] && (
                        <div key={key} className={classes.listItem}>
                          {key === 'Номер телефона' ? (
                            <a
                              href={`tel:${companyInfo[key]}`}
                              className={
                                key === 'Номер телефона'
                                  ? classes.listValueImportant
                                  : classes.listValue
                              }
                              dangerouslySetInnerHTML={{ __html: companyInfo[key] }}
                            />
                          ) : (
                            <p
                            onClick={() => {
                              history.push(`/products/list/U${product?.company?.id}/${product?.company?.id}`);
                            }}
                              style={{cursor: 'pointer'}}
                              className={
                                key === 'Сайт' ? classes.listValueImportant : classes.listValue
                              }
                              dangerouslySetInnerHTML={{ __html: companyInfo[key] }}
                            />
                          )}
                          <p className={classes.listKey}>{key}</p>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            )}

            <div className={classes.companyCard} style={{ marginTop: 25 }}>
              <div className={classes.statisticsHeader}>
                <Rating rating={reviewsProduct?.rating} />

                <Typography style={{ fontSize: 17, fontWeight: 'bold' }}>
                  {reviewsProduct?.rating || 0} / 5
                </Typography>
              </div>

              {/* {reviewsProduct && (
                <div className={classes.statisticsList}>
                  {Array(5)
                    .fill('')
                    .map((_, index) => {
                      const length =
                        reviewsProduct.reviews.filter(item => item.rating === 5 - index)
                          .length || 0;
                      const percent = (length * 100) / reviewsProduct.reviews.length || 0;

                      return (
                        <div key={index} className={classes.statisticsItem}>
                          <Typography className={classes.ratingInfoText}>
                            {5 - index} зв.
                          </Typography>
                          <div className='progress'>
                            <span style={{ width: `${percent}%` }} />
                          </div>
                          <Typography className={classes.ratingInfoText}>{length}</Typography>
                        </div>
                      );
                    })}
                </div>
              )} */}
            </div>
            {(isBuyer || isAdmin) && !meAdd && (
              <div className={classes.feedButton}>
                <Button className={classes.btn} onClick={() => setVisibleModalReviews(true)}>
                  <div className={classes.btnText}>{fm('REVIEW.CREATE')}</div>
                </Button>
              </div>
            )}
            {/* {product.company && product.company.phone_number && ( */}
            {/*  <a */}
            {/*    rel='noreferrer' */}
            {/*    target='_blank' */}
            {/*    href={`https://wa.me/${cleanPhone(product.company.phone_number)}`} */}
            {/*    className={classes.whatsAppButton} */}
            {/*  > */}
            {/*    {intl.formatMessage({ id: 'PRODUCT.VIEW.WHATSAPP' })} */}
            {/*  </a> */}
            {/* )} */}
          </div>
          <div className={classes.leftCol}>
            <div style={{ width: '100%' }}>
              <ReviewsForm product={product} meAdd={meAdd} name={me?.fio || ''} />
            </div>
          </div>
        </Card>
        <ModalReview
          product={product}
          open={visibleModalReviews}
          handleAgree={() => {}}
          handleClose={() => setVisibleModalReviews(false)}
        />
      </div>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    product: state.products.product,
    loading: state.products.byIdLoading,
    meAdd: state.products.reviewsProduct?.meAdd || false,
    isAuthorized: state.auth.user != null,
    guestCart: state.cart.guestCart,
    cart: state.cart.cart,
    setProductCountLoading: state.cart.setProductCountLoading,
    isAdmin: state.auth.user?.is_admin,
    me: state.profile.me,
    loadingMe: state.profile.loading,
    editLoading: state.products.editLoading,
    cartCompanyId: state.cart.companyId,
    companyGuestCart: state.cart.guestCart?.cartCompanyId,
    countProductsGuest: state.cart.guestCart?.items.length,
    addProductLoading: state.cart.addProductLoading,
  }),
  {
    fetch: productActions.fetchByIdRequest,
    clearProduct: productActions.clearProduct,
    setProductGuestCart: cartTypesActions.setProductGuestCart,
    setCountGuestCart: cartTypesActions.setCountGuestCart,
    setProductCount: cartTypesActions.setProductCountRequest,
    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,
    addProductToCart: cartTypesActions.addProductRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ProductView);
