import React, { ChangeEvent } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  createStyles,
  makeStyles,
  Card,
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import { ICompany } from '../../../../interfaces/companies';
import { toAbsoluteUrl } from '../../../../../_metronic';
import { API_DOMAIN } from '../../../../constants';
import { useFormatMessage } from '../../../../hooks';
import StarIcon from './StarIcon';

interface IAlertCompanyDetail extends DialogProps {
  handleAgree?: any;
  handleClose?: any;
  message?: string;
  company?: ICompany | null;
}

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      padding: '50px 20px',
      width: 700
    },
    paper: {
      overflowY: 'clip'
    },
    input_file: {
      position: 'relative',
      display: 'inline-block',
      height: '40px',
      padding: '20px'
    },
    span: {
      position: 'relative',
      display: 'inline-block',
      cursor: 'pointer',
      outline: 'none'
    },
    input: {
      position: 'absolute',
      zIndex: -1,
      opacity: 0,
      display: 'block',
      width: 0,
      height: 0,
    },
    imgContainer: {
      height: 400,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        maxWidth: 280,
        height: 200,
      },
      [theme.breakpoints.only('sm')]: {
        maxWidth: 350,
        height: 250,
      },
    },
    img: {
      maxWidth: 240,
      [theme.breakpoints.down('xs')]: {
        maxWidth: 170,
      },
      [theme.breakpoints.only('sm')]: {
        maxWidth: 190,
      },
      cursor: 'pointer',
    },
    listKey: {
      marginBottom: 0,
      fontSize: 17,
      fontWeight: 400,
      color: '#757575',
    },
    title: {
      marginBottom: 0,
      fontSize: 17,
      fontWeight: 400,
      color: '#d01c1f',
    },
    name: {
      fontSize: 20,
      fontWeight: 600,
      margin: 0,
      paddingTop: 10,

      [theme.breakpoints.up('md')]: {
        fontSize: 34,
        lineHeight: 1.2,
      },
    },
    listText: {
      marginBottom: 0,
      fontSize: 17,
      fontWeight: 'bold',
      color: '#000000',
      paddingLeft: 10
    },
    num: {
      marginBottom: 0,
      fontSize: 17,
      fontWeight: 'bold',
      color: '#d01c1f',
    },
    description: {
      fontSize: 17,
      fontWeight: 400,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '23px',
      letterSpacing: 'normal',
      color: '#000000',
      whiteSpace: 'break-spaces',
      margin: 0,
    },
    titleOut: {
      marginBottom: 0,
      fontSize: 20,
      fontWeight: 'bold',
      color: '#000000',
    },
    outKey: {
      marginBottom: 0,
      fontSize: 17,
      fontWeight: 400,
      color: '#000000',
    },
    outNum: {
      marginBottom: 0,
      fontSize: 17,
      fontWeight: 400,
      color: '#d01c1f',
    },
    subTitle: {
      marginBottom: 0,
      fontSize: 17,
      fontWeight: 600,
      color: '#000000',
    },
  })
);

const AlertCompanyDetail: React.FC<IAlertCompanyDetail> = ({
  open,
  handleClose,
  message = '',
  company,
}) => {
  const classes = useStyles();
  const fm = useFormatMessage();

  const titles = [
    "Главная база", 
    "Название второй точки", 
    "Название третьей точки",
    "Название четвертой точки",
    "Название пятой точки",
    "Название шестой точки",
    "Название седьмой точки",
  ];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      scroll='body'
    >
      <DialogActions style={{ overflow: 'hidden' }} >
        <div className={classes.container}>
          <Card
              className={classes.imgContainer}
              elevation={0}
              style={{ height: 'auto', maxHeight: 400, justifyContent: 'space-between',alignItems:'start' }}
            >
              <img
                src={
                  company?.logo
                    ? `${API_DOMAIN}/${company?.logo.path}`
                    : toAbsoluteUrl('/images/placeholder.png')
                }
                className={classes.img}
                alt=''
              />

              <p onClick={handleClose} style={{cursor: 'pointer'}} className={classes.title}>Закрыть</p>
            </Card>

            <p className={classes.name}>
                <b>{company?.brand}</b>
              </p>
            <p className={classes.listKey}>{fm('COMPANY.NAME.VIEW')}</p>

            {company?.rating && (
                <>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20}}>
                  <StarIcon />
                  <p className={classes.listText}>{company.rating}</p>
                </div>
                <p className={classes.listKey}>{fm('COMPANY.RATING.VIEW')}</p>
                </>
              )}
              
              {company?.name && (
                <>
                <p style={{marginTop: 20}} className={classes.title}>
                  <b>{company?.name}</b>
                </p>
                <p className={classes.listKey}>{fm('COMPANY.ENTITY.VIEW')}</p>
                </>
              )}
              
              {company?.phone_number && (
                <>
                <p style={{marginTop: 20}} className={classes.num}>
                  <b>{company.phone_number}</b>
                </p>
                <p className={classes.listKey}>{fm('COMPANY.NUM.VIEW')}</p>
                </>
              )}
              
              {company?.site && (
                <>
                <p style={{marginTop: 20}} className={classes.num}>
                  <b>{company?.site}</b>
                </p>
                <p className={classes.listKey}>{fm('COMPANY.SITE.VIEW')}</p>
                </>
              )}

              {/* <p style={{marginTop: 20}} className={classes.title}>
                <b>г. Москва, ул. Нижегородская, д.32</b>
              </p>
              <p className={classes.listKey}>{fm('COMPANY.ADDRESS.VIEW')}</p> */}

              <p style={{marginTop: 20}} className={classes.title}>
                <b>пн - сб 09:00 - 19:00</b>
              </p>
              <p className={classes.listKey}>{fm('COMPANY.HOURS.VIEW')}</p>

              {company?.description && (
                <p
                  className={classes.description}
                  style={{marginTop: 25}}
                  dangerouslySetInnerHTML={{ __html: company.description }}
                />
              )}

              {company?.stores && company.stores.length > 0 && (
                <p style={{marginTop: 25}} className={classes.titleOut}>
                  <b>Торговые точки</b>
                </p>
              )}
              
              {company?.stores && company?.stores.map((item, index) => (
                <>
                <p style={{marginTop: 20}} className={classes.subTitle}>
                  <b>{titles[index]}</b>
                </p>
                <p className={classes.outKey}>{item?.name}</p>
                <p className={classes.outNum}>{item?.phone_number}</p>
                </>
              ))}
        </div>
      </DialogActions>
    </Dialog >
  )
};

export default AlertCompanyDetail;
