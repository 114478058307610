/* eslint-disable react/button-has-type */
import React, { useCallback } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button, Dialog, Card, CardContent, Typography, CardActions } from '@material-ui/core';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { actions as productCatalogActions } from '../../../store/ducks/product-catalog.duck';
// import { ReactComponent as Logo } from '../../../../_metronic/layout/assets/layout-svg-icons/ruherb-logo4.svg';
import { StyledTreeItem } from '../../../pages/home/products/components/FilterCategoriesTree';
import { IAppState } from '../../../store/rootDuck';
import { useStyles } from './useStyles';

type TProps = {
  open: boolean;
  close: () => void;
  clear: () => void;
  selectedCategoryId: number | null;
  setSelectedCategoryId: (id: number) => void;
  setSearch: (value: boolean) => void;
};

const DialogCategories: React.FC<TProps> = ({
  open,
  close,
  clear,
  selectedCategoryId,
  setSelectedCategoryId,
  setSearch,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { catalogCategories } = useSelector((store: IAppState) => ({
    catalogCategories: store.categories.catalogCategories,
  }));

  const { filter } = useSelector((store: IAppState) => ({
    filter: store.productsCatalog.filter,
    loading: store.productsCatalog.loading,
  }));

  const fetch = useCallback(() => {
    setSearch(true);
    selectedCategoryId &&
      dispatch(
        productCatalogActions.fetchRequest({
          page: 1,
          perPage: 12,
          filter,
          categoryId: selectedCategoryId,
        })
      );
  }, [selectedCategoryId]);

  const select = (id: number) => {
    setSelectedCategoryId(id);
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      style={{ margin: 0, padding: 0, height: '100%' }}
      disableScrollLock
      classes={{
        paper: classes.dialog,
      }}
    >
      <Card className={classes.cardCategory}>
        <CardContent className={classes.cardContent}>
          <Link
            to='/products/catalog'
            onClick={() => {
              clear();
              close();
            }}
          >
            {/* <div className='kt-header-logo'> */}
            <p style={{ margin: 0, fontWeight: 'bold', fontSize: 15, color: '#000000' }}>
              Одежда оптом
            </p>
            {/* </div> */}
          </Link>
          <Typography
            variant='h5'
            className={classes.labelText}
            style={{ marginBottom: 16, marginTop: 20 }}
          >
            {intl.formatMessage({ id: 'CATEGORIES.TITLE' })}
          </Typography>
          <ul className={classes.root}>
            <StyledTreeItem
              categories={catalogCategories}
              select={select}
              setSelectChildrenId={setSelectedCategoryId}
              selectChildrenId={selectedCategoryId}
              locale={intl.locale}
            />
            {/* {catalogCategories.map(item => ( */}
            {/*  <StyledTreeItem */}
            {/*    select={select} */}
            {/*    selectedCategoryId={selectedCategoryId} */}
            {/*    item={item} */}
            {/*    classes={classes} */}
            {/*  /> */}
            {/* ))} */}
          </ul>
        </CardContent>
        <CardActions className={classes.cardButtons}>
          <Button
            className={clsx(classes.buttonReset)}
            color='primary'
            variant='outlined'
            onClick={() => {
              close();
              clear();
            }}
          >
            {intl.formatMessage({ id: 'PRODUCT.FILTER.RESET' })}
          </Button>
          <Button
            disabled={Boolean(!selectedCategoryId)}
            className={clsx(classes.button)}
            color='primary'
            variant='contained'
            onClick={() => {
              location.pathname !== '/products/catalog' &&
                history.push('/products/catalog', { categoryID: selectedCategoryId });
              close();
              fetch();
            }}
          >
            {intl.formatMessage({ id: 'PRODUCT.FILTER.SEARCH' })}
          </Button>
        </CardActions>
      </Card>
    </Dialog>
  );
};

export default DialogCategories;
