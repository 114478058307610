import React from 'react';
import UserProfile from '../../partials/layout/UserProfile';
import { createStyles, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import SearchField from "../../../app/components/ui/SearchField";
import { UserRoles } from "../../../app/interfaces/user";

const useStyles = makeStyles(theme =>
  createStyles({
    menuContainer: {
      display: 'block',
      backgroundColor: 'red',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    menu: {
      width: 25,
      height: 20,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  })
);

const Topbar = props => {
  const classes = useStyles();
  return (
    <div
      className='kt-header__topbar'
      // style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexGrow: 1 }}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 'calc(100%)', gap: 100 }}
    >
      <div className={classes.menuContainer}>
        {props.showBurger ? (
          <div id='kt_header_mobile_toggler' className={classes.menu}>
            <span
              style={{ height: 3, borderRadius: 3, width: '100%', backgroundColor: '#cecece' }}
            />
            <span
              style={{ height: 3, borderRadius: 3, width: '100%', backgroundColor: '#cecece' }}
            />
            <span
              style={{ height: 3, borderRadius: 3, width: '100%', backgroundColor: '#cecece' }}
            />
          </div>
        ) : (
          <Link to={'/products/catalog'}>
            <p
              style={{
                margin: 0,
                fontWeight: 'bold',
                fontSize: 15,
                padding: '5px 10px',
                backgroundColor: '#eaeaea',
                borderRadius: 5,
              }}
            >
              Каталог
            </p>
          </Link>
        )}
      </div>
      <div />
      {/* {
        props?.me?.roles.includes(UserRoles.ROLE_BUYER) ? (
          <>
            <SearchField isSubHeader={true} />
          </>
        ) : (
          !(props?.me) ? (
            <>
              <SearchField isSubHeader={true} />
            </>
          ) : (<></>)
        )
      } */}
      <UserProfile showAvatar={true} showHi={true} showBadge={false} />
    </div>
  );
};

export default Topbar;
