import React, { useLayoutEffect } from 'react';
import { TextField } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { DialogProps } from '@material-ui/core/Dialog';
import { Button } from '../../../../components/ui/Buttons';
import { useCreateReview } from '../hooks/useCreateReviewFormik';
import { IProduct } from '../../../../interfaces/product';
import { useFormatMessage } from '../../../../hooks';
import Rating from './Rating';
import { useStylesModalReview } from '../hooks/useStyles';

interface IModalReview extends DialogProps {
  handleAgree: any;
  handleClose: any;
  okText?: string;
  cancelText?: string;
  message?: string;
  product: IProduct;
}

const ModalReview: React.FC<IModalReview> = ({
  open,
  handleClose,
  handleAgree,
  okText = 'OK',
  cancelText = 'Cancel',
  message = '',
  product,
}) => {
  const fm = useFormatMessage();
  const classes = useStylesModalReview();
  const [createRevieFormik, loadingCreate, successCreate] = useCreateReview(product.id);

  useLayoutEffect(() => {
    if (successCreate) {
      handleClose();
    }
  }, [successCreate]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
      BackdropProps={{
        className: classes.backdrop,
      }}
    >
      <form onSubmit={createRevieFormik.handleSubmit} className={classes.modalContent}>
        {/* Содержимое модального окна */}
        <h2 className={classes.titleCreate}>{fm('CREATE.REVIEW')}</h2>
        <div className={classes.statisticsHeader}>
          <Rating
            rating={createRevieFormik.values.rating}
            onChange={rating => createRevieFormik.setFieldValue('rating', rating)}
          />
        </div>
        <h2 className={classes.titleDesc}>{fm('CREATE.REVIEW.DESC')}</h2>
        <TextField
          type='text'
          margin='none'
          multiline
          rows={19}
          variant='outlined'
          name='text'
          value={createRevieFormik.values.text}
          onBlur={createRevieFormik.handleBlur}
          onChange={createRevieFormik.handleChange}
          helperText={createRevieFormik.touched.text && createRevieFormik.errors.text}
          error={Boolean(createRevieFormik.touched.text && createRevieFormik.errors.text)}
          className={classes.input}
        />
        <div className={classes.containerButtons}>
          <Button
            onClick={handleClose}
            color='inherit'
            className={`${classes.btn} ${classes.buttonFormLeft}`}
            variant='outlined'
          >
            <div className={classes.btnText}>{fm('COMMON.BUTTON.CANCEL')}</div>
          </Button>
          <Button
            disabled={loadingCreate}
            type='submit'
            className={classes.buttonForm}
            color='primary'
          >
            <div className={classes.btnText}>{fm('CREATE.REVIEW.BTN')}</div>
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ModalReview;
