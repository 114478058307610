import React from 'react';
import { Theme, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  buttons: {
    color: 'red',
    borderColor: '#eb7f7f',
    '&:hover': {
      borderColor: 'red',
    },
  },
}));

interface IProps {
  type?: 'button' | 'reset' | 'submit' | undefined;
  onClick?: () => {} | void;
}

const noop = () => { };

const RedOutlinedButton: React.FC<IProps> = ({ children, type = 'button', onClick = noop }) => {
  const classes = useStyles();

  return (
    <Button
      type={type}
      variant="outlined"
      color="inherit"
      onClick={onClick}
      className={classes.buttons}
    >
      {children}
    </Button>
  );
};

export default RedOutlinedButton;
