import { useFormik } from 'formik';
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { useIsFirstRender } from '../../../hooks/useIsFirstRender';
import { countLettersInString } from '../../../utils/utils';

export const useFormikRegistration = (
  tab: number,
  initPhone: string = '',
  initEmail: string = '',
  countryData: any,
  makeRequest: any
) => {
  const intl = useIntl();
  const isFirstRender = useIsFirstRender();

  const validPhoneLength = useMemo(() => {
    if (!countryData) return null;
    return countLettersInString(countryData.mask, '*');
  }, [countryData]);

  const validationSchema = useMemo(() => {
    let shape = null;
    if (tab === 0) {
      shape = {
        email: Yup.string()
          .email(intl.formatMessage({ id: 'AUTH.INPUT.EMAIL_INCORRECT' }))
          .required(intl.formatMessage({ id: 'PROFILE.VALIDATION.REQUIRED_FIELD' })),
      };
    } else {
      shape = {
        phone: Yup.string()
          .test(
            'check if phone length valid',
            intl.formatMessage({ id: 'NOT_VALID_PHONE' }),
            value => {
              if (value && validPhoneLength && value.length !== validPhoneLength) {
                return false;
              }
              return true;
            }
          )
          .required(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' })),
      };
    }
    return Yup.object().shape({
      ...shape,
      acceptTerms: Yup.boolean().oneOf([true], 'Must Accept Terms and Conditions'),
    });
  }, [tab, countryData, intl]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: initEmail,
      phone: initPhone,
      role: 'ROLE_VENDOR',
      fio: '',
      acceptTerms: false,
      locale: 'ru',
    },
    validationSchema,
    onSubmit: submitValues => {
      makeRequest(tab === 0 ? 'email' : 'phone', submitValues, countryData);
    },
  });

  // clear phone value when country changed
  useEffect(() => {
    if (isFirstRender) return;
    formik.setFieldValue('phone', '');
  }, [countryData]);

  return formik;
};
