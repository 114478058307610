import axios from 'axios';

export const getTenders = (page: number, per_page: number, categories?: string, user_id?: string | number) => {
  return axios.get('/api/shop/tenders', { params: { page, per_page, categories, user_id } });
};

export const getTenderById = (id: number) => axios.get(`/api/shop/tender/${id}`);

export const createTender = (tender: any) => axios.post(`/api/shop/tender/add`, tender);

export const editTender = (id: number, order: any) =>
  axios.put(`/api/shop/tender/${id}`, order);

export const uploadFiles = (id: number, data: FormData) =>
  axios.post(`/api/shop/tender/${id}/add_attachment`, data);

export const deleteFile = (id: number) => axios.delete(`/api/shop/tender/attachment/${id}`);

export const getParticipations = (
  tender_id: number,
  page: number,
  per_page: number,
  company_id?: number,
  statuses?: string
) =>
  axios.get(`/api/shop/proposals`, {
    params: { tender_id, company_id, per_page, page, statuses },
  });

export const createParticipation = (id: number, data: any) =>
  axios.post(`/api/shop/tender/${id}/add_proposal`, data);

export const deleteParticipation = (id: number) => axios.delete(`/api/shop/proposal/${id}`);

export const deleteTender = (id: number) => axios.delete(`/api/shop/tender/${id}`);
