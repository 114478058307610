import { makeStyles, createStyles } from '@material-ui/core';

export const companyInfoStyles = makeStyles(theme =>
  createStyles({
    card: {
        width: '97%',
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          marginBottom: 20,
        },
      },
      name: {
        fontSize: 20,
        fontWeight: 600,
        margin: 0,
        [theme.breakpoints.up('md')]: {
          fontSize: 34,
          lineHeight: 1,
        },
      },
      listKey: {
        marginBottom: 0,
        fontSize: 17,
        fontWeight: 400,
        color: '#757575',
      },
  })
);
