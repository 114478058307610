import React, { FC, useCallback, useState } from 'react';
import {
  Button,
  CardActions,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core';
import TablePaginator from '../../../../components/TablePaginator/TablePaginator';
import { formatAsThousands } from '../../../../utils/utils';
import { toAbsoluteUrl } from '../../../../../_metronic';
import { IProduct } from '../../../../interfaces/product';
import { useDefineUserRole, useFormatMessage } from '../../../../hooks';
import { IUser, UserRoles } from '../../../../interfaces/user';
import { getProductImage } from '../utils/getProductImage';
import { useStylesProductsGrid } from '../hooks/useStyles';
import StarIcon from './StarIcon';

interface IProductsProps {
  data: IProduct[];
  page?: number;
  perPage?: number;
  total?: number;
  categoryId: string;
  companyId: string;
  fetch?: (data: { page: number; perPage: number }) => void;
  viewAction: any;
  editAction?: any;
  handleDeleteDialog?: any;
  isAdmin: boolean;
  me: IUser | undefined;
  addProductLoading: boolean;
  handleCartDialog: any;
  isPaginator?: boolean;
}

const ProductsGrid: FC<IProductsProps> = ({
  data,
  categoryId,
  page,
  perPage,
  total,
  fetch,
  companyId,
  viewAction,
  editAction,
  handleDeleteDialog,
  isAdmin,
  me,
  addProductLoading,
  handleCartDialog,
  isPaginator = true,
}) => {
  const fm = useFormatMessage();
  const classes = useStylesProductsGrid();
  const isBuyer = useDefineUserRole(me, 'ROLE_BUYER');
  const [heightImg, setHeightImg] = useState(344);
  const getStock = useCallback(
    (product: IProduct) => {
      return isBuyer || !me ? product.stock_info?.available_quantity : product?.stock_quantity;
    },
    [isBuyer, me]
  );
  // const changeHide = useCallback((item: IProduct) => {
  //   if (isHideProduct) {
  //     const data = new FormData();
  //     data.append('active', '0');
  //     edit({ id: Number(item?.id), data });
  //   }
  // }, []);

  return (
    <>
      <div className={classes.container}>
        {data.map(item => {
          const onePiece = Number(item.price) / (item.units ?? 1);

          if (
            !(
              (!getStock(item) || (getStock(item) || 0) < 0) &&
              !me?.roles.includes(UserRoles.ROLE_VENDOR) &&
              !isAdmin
            )
          ) {
            return (
              <Card key={item.id} className={classes.productCard}>
                <div className={classes.ratingContent}>
                  <Typography
                    gutterBottom
                    variant='h5'
                    component='h2'
                    style={{
                      display: 'flex',
                      paddingTop: '9px',
                      marginLeft: '2px',
                      justifyContent: 'center',
                    }}
                  >
                    <StarIcon size='22px' />

                    <b style={{ marginLeft: '3px' }}>{formatAsThousands(item.rating || '')}</b>
                  </Typography>
                </div>

                <CardMedia
                  style={{
                    height: heightImg,
                    width: '101%',
                    // minHeight: 324,
                    objectFit: 'cover',
                    // opacity: !getStock(item) || (getStock(item) || 0) < 0 ? 0.1 : 1,
                  }}
                  title={item.name}
                  onClick={() => viewAction(item)}
                  src={getProductImage(item)}
                  component='img'
                  onError={(e: any) => {
                    e.target.src = toAbsoluteUrl('/images/placeholder.png');
                  }}
                />

                <CardContent onClick={() => viewAction(item)}>
                  <Typography color='inherit' component='p' className={classes.name}>
                    {item.name}
                  </Typography>
                  <div className={classes.containerPriceAndRating}>
                    <Typography variant='h5' component='h2' className={classes.price}>
                      <b>{formatAsThousands(item.price)} руб.</b>
                    </Typography>
                  </div>
                  {item.units && (
                    <Typography color='inherit' component='p' className={classes.units}>
                      {item.units || ''} {fm('TH')}.{' '}({onePiece.toFixed(0)} руб. {fm('FOR')} 1 {fm('TH')}.)
                    </Typography>
                  )}
                  {/* <Typography color='textSecondary' component='p'>
                    {item.modification.gearbox_type.name}
                  </Typography> */}
                </CardContent>
                <CardActions className={classes.containerBtns}>
                  {!me?.roles.includes(UserRoles.ROLE_VENDOR) && !isAdmin && (
                    <div className={classes.btnAdd}>
                      <Button
                        variant='outlined'
                        disabled={
                          addProductLoading || !getStock(item) || (getStock(item) || 0) < 0
                        }
                        onClick={() => {
                          handleCartDialog(item);
                        }}
                        color={
                          !getStock(item) || (getStock(item) || 0) < 0
                            ? 'secondary'
                            : 'primary'
                        }
                      >
                        {!getStock(item) || (getStock(item) || 0) < 0
                          ? fm('PRODUCT.BUTTON.SOLD.OUT')
                          : fm('PRODUCT.BUTTON.ADD_CART')}
                      </Button>
                    </div>
                  )}

                  { (isAdmin || (me?.company?.id === item.company?.id)) && (
                    <>
                      <Button onClick={() => editAction(item)} color='primary'>
                        {fm('edit')}
                      </Button>

                      <Button
                        onClick={() => {
                          handleDeleteDialog(item?.id);
                          // changeHide(item);
                        }}
                        color='secondary'
                        className={classes.btnDelete}
                      >
                        {fm('COMMON.BUTTON.DELETE')}
                      </Button>
                    </>
                  )}
                </CardActions>
              </Card>
            );
          }

          if (!isAdmin && item?.company?.favorite) {
            return (
              <Card key={item.id} className={classes.productCard}>
                <div className={classes.ratingContent}>
                  <Typography
                    gutterBottom
                    variant='h5'
                    component='h2'
                    style={{
                      display: 'flex',
                      paddingTop: '9px',
                      marginLeft: '2px',
                      justifyContent: 'center',
                    }}
                  >
                    <StarIcon size='22px' />

                    <b style={{ marginLeft: '3px' }}>{formatAsThousands(item.rating || '')}</b>
                  </Typography>
                </div>

                <CardMedia
                  style={{
                    height: heightImg,
                    // minHeight: 324,
                    objectFit: 'cover',
                    // opacity: !getStock(item) || (getStock(item) || 0) < 0 ? 0.1 : 1,
                  }}
                  title={item.name}
                  onClick={() => viewAction(item)}
                  src={getProductImage(item)}
                  component='img'
                  onError={(e: any) => {
                    e.target.src = toAbsoluteUrl('/images/placeholder.png');
                  }}
                />
                <CardContent onClick={() => viewAction(item)}>
                  <Typography color='inherit' component='p' className={classes.name}>
                    {item.name}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant='h5'
                    component='h2'
                    style={{ lineHeight: 1 }}
                  >
                    <b>{formatAsThousands(item.price)} руб.</b>
                  </Typography>
                  {item.units && (
                    <Typography color='inherit' component='p' className={classes.units}>
                      {item.units || ''} {fm('TH')}.{' '}({onePiece.toFixed(0)} руб. {fm('FOR')} 1 {fm('TH')}.)
                    </Typography>
                  )}
                  {/* <Typography color='textSecondary' component='p'>
                    {item.modification.gearbox_type.name}
                  </Typography> */}
                </CardContent>
                <CardActions className={classes.containerBtns}>
                  {!me?.roles.includes(UserRoles.ROLE_VENDOR) && !isAdmin && (
                    <div className={classes.btnAdd}>
                      <Button
                        disabled={
                          addProductLoading || !getStock(item) || (getStock(item) || 0) < 0
                        }
                        onClick={() => {
                          handleCartDialog(item);
                        }}
                        color={
                          !getStock(item) || (getStock(item) || 0) < 0
                            ? 'secondary'
                            : 'primary'
                        }
                      >
                        {!getStock(item) || (getStock(item) || 0) < 0
                          ? fm('PRODUCT.BUTTON.SOLD.OUT')
                          : fm('PRODUCT.BUTTON.ADD_CART')}
                      </Button>
                    </div>
                  )}
                  { (isAdmin || (me?.company?.id === item.company?.id)) && (
                    <>
                      <Button onClick={() => editAction(item)} color='primary'>
                        {fm('edit')}
                      </Button>

                      <Button
                        onClick={() => {
                          handleDeleteDialog(item?.id);
                          // changeHide(item);
                        }}
                        color='secondary'
                        className={classes.btnDelete}
                      >
                        {fm('COMMON.BUTTON.DELETE')}
                      </Button>
                    </>
                  )}
                </CardActions>
              </Card>
            );
          }
          if (
            !(
              (!getStock(item) || (getStock(item) || 0) < 0) &&
              me?.company?.id === item?.company?.id
            ) &&
            !item.company?.favorite
          ) {
            return (
              <Card key={item.id} className={classes.productCard}>
                <div className={classes.ratingContent}>
                  <Typography
                    gutterBottom
                    variant='h5'
                    component='h2'
                    style={{
                      display: 'flex',
                      paddingTop: '9px',
                      marginLeft: '2px',
                      justifyContent: 'center',
                    }}
                  >
                    <StarIcon size='22px' />

                    <b style={{ marginLeft: '3px' }}>{formatAsThousands(item.rating || '')}</b>
                  </Typography>
                </div>

                <CardMedia
                  style={{
                    height: heightImg,
                    // minHeight: 324,
                    objectFit: 'cover',
                    // opacity: !getStock(item) || (getStock(item) || 0) < 0 ? 0.1 : 1,
                  }}
                  title={item.name}
                  onClick={() => viewAction(item)}
                  src={getProductImage(item)}
                  component='img'
                  onError={(e: any) => {
                    e.target.src = toAbsoluteUrl('/images/placeholder.png');
                  }}
                />
                <CardContent onClick={() => viewAction(item)}>
                  <Typography color='inherit' component='p' className={classes.name}>
                    {item.name}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant='h5'
                    component='h2'
                    color='primary'
                    style={{ lineHeight: 1 }}
                  >
                    <b>{formatAsThousands(item.price)} руб.</b>
                  </Typography>
                  {item.units && (
                    <Typography color='inherit' component='p' className={classes.units}>
                      {item.units || ''} {fm('TH')}.{' '}({onePiece.toFixed(0)} руб. {fm('FOR')} 1 {fm('TH')}.)
                    </Typography>
                  )}
                  {/* <Typography color='textSecondary' component='p'>
                    {item.modification.gearbox_type.name}
                  </Typography> */}
                </CardContent>
                <CardActions className={classes.containerBtns}>
                  {!me?.roles.includes(UserRoles.ROLE_VENDOR) && !isAdmin && (
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        height: 45,
                      }}
                    >
                      <Button
                        // disabled={
                        //   addProductLoading || !getStock(item) || (getStock(item) || 0) < 0
                        // }
                        onClick={() => {
                          handleCartDialog(item);
                        }}
                        color='primary'
                      >
                        {/* {!getStock(item) || (getStock(item) || 0) < 0 */}
                        {/*  ? fm('PRODUCT.BUTTON.SOLD.OUT') */}
                        {/*  : fm('PRODUCT.BUTTON.ADD_CART')} */}
                        {fm('PRODUCT.BUTTON.ADD_CART')}
                      </Button>
                    </div>
                  )}
                  { (isAdmin || (me?.company?.id === item.company?.id)) && (
                    <>
                      <Button onClick={() => editAction(item)} color='primary'>
                        {fm('edit')}
                      </Button>

                      <Button
                        onClick={() => {
                          handleDeleteDialog(item?.id);
                          // changeHide(item);
                        }}
                        color='secondary'
                        className={classes.btnDelete}
                      >
                        {fm('COMMON.BUTTON.DELETE')}
                      </Button>
                    </>
                  )}
                </CardActions>
              </Card>
            );
          }
        })}
      </div>
      {isPaginator && fetch && (
        <div style={{ display: 'flex', justifyContent: 'center' }} id='catalog-paginator'>
          <TablePaginator
            id={+categoryId}
            page={page || 1}
            perPage={perPage || 20}
            total={total || 0}
            realPerPage={data.length}
            fetchRows={fetch}
            companyId={companyId}
            rowsPerPageOptions={[24, 42, 108]}
            label={fm('PRODUCT.TABLE.PERPAGE')}
          />
        </div>
      )}
    </>
  );
};

export default React.memo(ProductsGrid);
