import React, { useCallback, useState } from 'react';
import { Card, CardContent, Button, CardActions } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Typography from '@material-ui/core/Typography';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { ICategoryTreeTable } from '../interfaces';
import { ICategoryItem } from '../../categories/interfaces';
import { useTreeItemStyles } from '../hooks/useStyles';

const FilterCategoriesTree: React.FC<ICategoryTreeTable> = ({
  categories,
  resetProducts,
  searchProducts,
  filter,
  setSearch,
  setSelectedCategoryId,
  selectedCategoryId,
  searchForCategory,
  companyId,
  isAdmin,
  isVender
}) => {
  const intl = useIntl();
  const classes = useTreeItemStyles();
  const [selectChildrenId, setSelectChildrenId] = useState<number>(0);

  const fetch = useCallback(() => {
    setSearch(true);
    searchForCategory && searchForCategory(selectChildrenId);
    if (searchProducts) {
      if (selectedCategoryId && filter && companyId) {
        if(isAdmin || isVender){
          searchProducts({ page: 1, perPage: 24, filter, companyId, categoryId: selectChildrenId,});
        }else
        searchProducts({ page: 1, perPage: 24, filter, companyId, categoryId: selectChildrenId, active: true});
      } else if (selectedCategoryId && filter && !companyId) {
        if(isAdmin){
          searchProducts({ page: 1, perPage: 24, filter, companyId, categoryId: selectChildrenId,});
        }else {
          searchProducts({ page: 1, perPage: 24, filter, companyId, categoryId: selectChildrenId, active: true});
        }
       
      }
    }
  }, [selectedCategoryId, filter, searchProducts, selectChildrenId, searchForCategory, companyId]);

  const select = (id: number) => {
    setSelectedCategoryId(id);
  };

  const clear = () => {
    resetProducts();
    if (filter && filter.parameters.length === 0 && !filter.price_from && !filter.price_to) {
      setSearch(false);
    }
    setSelectedCategoryId(null);
  };
  return (
    <Card className={classes.cardCategory}>
      <CardContent>
        <Typography variant='h5' className={classes.labelTitle} style={{ marginBottom: 16 }}>
          {intl.formatMessage({ id: 'CATEGORIES.TITLE' })}
        </Typography>
        <ul className={classes.root}>
          <StyledTreeItem
            categories={categories}
            select={select}
            setSelectChildrenId={setSelectChildrenId}
            selectChildrenId={selectChildrenId}
            locale={intl.locale}
          />
        </ul>
      </CardContent>
      <CardActions className={classes.cardButtons}>
        <Button onClick={clear} className={classes.buttons} color='secondary'>
          {intl.formatMessage({ id: 'PRODUCT.FILTER.RESET' })}
        </Button>
        <Button
          disabled={Boolean(!selectedCategoryId)}
          className={clsx(classes.button)}
          color='primary'
          variant='contained'
          onClick={fetch}
        >
          {intl.formatMessage({ id: 'PRODUCT.FILTER.SEARCH' })}
        </Button>
      </CardActions>
    </Card>
  );
};

type TestProps = {
  select: (id: number) => void;
  categories: ICategoryItem[];
  selectChildrenId: number | null;
  setSelectChildrenId: (id: number) => void;
  locale: string;
};
export default FilterCategoriesTree;

export const StyledTreeItem = ({
  categories,
  select,
  setSelectChildrenId,
  selectChildrenId,
  locale,
}: TestProps) => {
  return (
    <li>
      {Object.entries(categories).map(([cartKey, item])=> {
        return (
          item.products_num ? 
            <StyledTreeSubItem
              category={item}
              select={select}
              selectChildrenId={selectChildrenId}
              setSelectChildrenId={setSelectChildrenId}
              locale={locale}
            />
          : null
      )})}
    </li>
  );
};

type TestPropsItem = {
  category: ICategoryItem;
  select: (id: number) => void;
  selectChildrenId: number | null;
  setSelectChildrenId: (id: number) => void;
  locale: string;
};
export const StyledTreeSubItem = ({
  category,
  select,
  setSelectChildrenId,
  selectChildrenId,
  locale,
}: TestPropsItem) => {
  const classes = useTreeItemStyles();
  const [selectChild, setSelectChild] = useState<boolean>(false);

  // @ts-ignore
  const nameLang = locale === 'ru' ? category.name : category.names[`name_${locale}`];

  const handleItem = (id: number) => {
    select(id);
    category && category.id && setSelectChildrenId(category.id);
  };
  const onClickHandler = () => {
    setSelectChild(!selectChild);
  };

  return (
    <li style={{ marginLeft: '20px' }}>
      <div
        onClick={() => {
          onClickHandler();
          category && category.id && handleItem(category.id);
          category && category.id && setSelectChildrenId(category.id);
        }}
        className={clsx(classes.labelRoot, {
          [classes.activeItem]: category.id === selectChildrenId,
        })}
      >
        <div style={{ position: 'absolute', left: -20 }}>
          {Boolean(category.children?.length) &&
            (selectChild ? <ArrowDropDownIcon /> : <ArrowRightIcon />)}
        </div>
        <Typography
          variant='body2'
          className={!nameLang ? classes.fontNoLang : classes.labelText}
          style={{ color: category.id === selectChildrenId ? '#d01c1f' : '#000000' }}
        >
          {nameLang || category.name}
        </Typography>
      </div>
      {selectChild && category.children?.length ? (
        <StyledTreeItem
          categories={category.children}
          select={select}
          selectChildrenId={selectChildrenId}
          setSelectChildrenId={setSelectChildrenId}
          locale={locale}
        />
      ) : null}
    </li>
  );
};
