import { createStyles, makeStyles } from '@material-ui/core';

export const useStylesReviewsList = makeStyles(() => ({
  container: {
    width: '100%',
    maxWidth: 700,
    margin: 'auto',
    padding: '40px 20px',
  },

  textTotalRating: {
    fontStyle: 'bold',
    fontWeight: 600,
    color: '#000',
    marginBottom: 30,
    textAlign: 'center',
  },

  wrapperBlock: {
    marginBottom: '2.25rem',
    '&:last-child': {
      margin: 0,
    },
  },

  wrapperUserInfo: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '2rem',
    flexWrap: 'wrap',
  },

  wrapperUserAvatar: {
    display: 'inline-block',
    flexShrink: 0,
    position: 'relative',
    borderRadius: '.475rem',
    marginRight: '1.25rem',
  },

  userAvatar: {
    width: 50,
    height: 50,
    borderRadius: '0.475rem',
  },

  userFio: {
    fontWeight: 600,
    fontSize: '1.15rem',
    wordWrap: 'break-word',
    color: '#3f4254',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },

  reviewText: {
    margin: 0,
    fontWeight: 400,
    fontSize: '1.15rem',
    color: '#5e6278',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },

  wrapperPaginator: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export const useStylesProductFavorites = makeStyles(theme =>
  createStyles({
    empty: {
      marginBottom: 20,
      marginTop: 20,
    },
    buttons: {
      marginRight: theme.spacing(3),
    },
    card: {
      marginBottom: theme.spacing(3),
    },
    categoryTree: {
      maxWidth: 400,
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    img: {
      maxHeight: 55,
    },
    countReviews: {
      fontSize: 14,
      fontFamily: 'Open Sans',
      fontWeight: 400,
      color: '#5D78FFFF',
    },
  })
);

export const useStylesProductCatalog = makeStyles(theme =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    empty: {
      marginBottom: 20,
      marginTop: 20,
    },
    card: {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('md')]: {
        marginTop: 20,
      },
    },
    count: {
      fontStyle: 'bold',
      fontWeight: 600,
      color: '#000',
      marginRight: 15,
      fontSize: 18,
    },
    icon: {
      width: 22,
      height: 22,
    },
    filterCol: {
      width: 326,
    },
    productsCol: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 'calc(100% - 326px - 15px)',
      },
    },
    revers: {
      display: 'flex',
      flexDirection: 'column-reverse',
      justifyContent: 'flex-end',
    },
    productColHeader: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 15,
    },
    productColHeaderItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    title: {
      fontStyle: 'bold',
      fontWeight: 800,
      color: '#1a2528',
      marginLeft: 20,
      fontSize: 18,
      [theme.breakpoints.down('sm')]: {
        fontSize: 11,
        marginLeft: 7,
      },
      // @ts-ignore
      [theme.breakpoints.up('sm')]: {
        fontSize: 14,
        marginLeft: 7,
      },
    },
    logo_container: {
      display: 'flex',
      flexDirection: 'row',
      background: '#ffffff',
      // [theme.breakpoints.down(920)]: {
      //   display: 'none',
      // },
      [theme.breakpoints.down('sm')]: {
        width: '73%',
      },
      [theme.breakpoints.up('sm')]: {
        width: '55%',
      },
      [theme.breakpoints.up('md')]: {
        width: '55%',
      },
      [theme.breakpoints.up('lg')]: {
        width: '45%',
      },
      [theme.breakpoints.up('xl')]: {
        width: '30%',
      },
      // @ts-ignore
      [theme.breakpoints.up('xxl')]: {
        width: '22.5%',
      },
      height: 40,
      alignItems: 'center',
      paddingLeft: 10,
      borderRadius: 4,
      position: 'relative',
    },
    cansel_content: {
      cursor: 'pointer',
      position: 'absolute',
      top: 0,
      right: 5,
    },
  })
);

export const useStylesFilterType = makeStyles(theme =>
  createStyles({
    cardCategory: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      top: 100,
      boxShadow: 'none !important',
      overflow: 'visible',
      [theme.breakpoints.up('md')]: {},
    },
    filterButton: {
      backgroundColor: '#fff',
      cursor: 'pointer',
      width: '100%',
      height: 42,
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 600,
      color: '#404355',
      fontSize: 14,
      lineHeight: '42px',

      '&.disabled': {
        backgroundColor: '#eee',
        color: '#d6d6d6',
        cursor: 'not-allowed',
      },
    },
    showAllButton: {
      marginRight: 10,
      color: '#b5b5c3',
      fontWeight: 600,

      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    button: {
      '&:active': {
        opacity: 0.2,
      },
    },
    cardButtons: {
      justifyContent: 'flex-end',
      padding: 16,
    },
  })
);

export const useStylesSelectFiltepType = makeStyles((theme: any) => ({
  rowFilter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  notchedOutline: {
    borderWidth: 0,
    borderColor: `transparent !important`,
  },
  cssOutlinedInput: {
    fontWeight: 600,
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: '#181c32',
    },
    '&$cssFocused $notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  input: {
    height: 38,
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: '#f5f8fa',
    padding: '0 8px',
    borderRadius: 4,
  },
  label: {
    marginBottom: 7,
    fontSize: 14,
    fontWeight: 600,
    color: '#181c32',
  },
  cssFocused: {},
  filterItem: {
    transition: '0.3s ease-out',

    '&.hide': {
      [theme.breakpoints.down('sm')]: {
        height: 0,
        transform: 'scaleY(0)',
        transformOrigin: 'top',
        overflow: 'hidden',
      },
    },
  },
}));

export const useStylesProductsGrid = makeStyles(theme =>
  createStyles({
    // container: {
    //   marginBottom: theme.spacing(3),
    //   display: 'flex',
    //   flexWrap: 'wrap',
    //   justifyContent: 'flex-start',
    //   marginTop: -15,
    // },
    // productCard: {
    //   width: '30%',
    //   minWidth: 250,
    //   maxWidth: 300,
    //   margin: 15,
    //   cursor: 'pointer',
    //   transition: '.2s ease-out',
    //   boxShadow: 'none !important',
    //   '&:hover': {
    //     transform: 'scale(1.03)',
    //   },
    // },
    container: {
      display: 'grid',
      gridGap: 24,
      gridTemplateColumns: 'repeat(4, 220px)',

      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr',
      },
      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
      [theme.breakpoints.up('xl')]: {
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
      // @ts-ignore
      [theme.breakpoints.up('xxl')]: {
        gridTemplateColumns: 'repeat(5, 1fr)',
      },
    },

    productCard: {
      // minWidth: '100%',
      marginBottom: 25,
      cursor: 'pointer',
      transition: '.3s ease-out',
      boxShadow: 'none !important',
      position: 'relative',
      '&:hover': {
        transform: 'scale(1.03)',
      },
    },
    name: {
      marginBottom: 10,
      lineHeight: 1.3,
      height: 45,
      fontSize: 17,
      fontWeight: 600,
      overflow: 'hidden',
    },
    containerPriceAndRating: {
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    rating: {
      display: 'flex',
      width: '25%',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    price: {
      lineHeight: 1,
      color: '#d01c1f',
    },
    btnAdd: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      height: 45,
      flexWrap: 'wrap',
    },
    containerBtns: {
      padding: '0px 7px 7px 7px',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
      },
    },
    btnDelete: {
      marginLeft: 8,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
    },
    ratingContent: {
      position: 'absolute',
      right: '20px',
      top: '0px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    units: {
      marginTop: 10,
      // lineHeight: 1,
      fontSize: 15,
      fontWeight: 400,
      overflow: 'hidden',
      padding: 0,
    },
  })
);

export const useStylesProductsListComponent = makeStyles(theme =>
  createStyles({
    empty: {
      marginBottom: 20,
    },
    buttons: {
      marginRight: theme.spacing(3),
    },
    card: {
      marginBottom: theme.spacing(3),
      boxShadow: 'none !important',
    },
    categoryTree: {
      maxWidth: 400,
    },
    actions: {
      marginTop: theme.spacing(2),
    },
    img: {
      maxHeight: 55,
    },
    autoLoc: {
      maxWidth: 400,
    },
    icon: {
      color: '#d01c1f',
      width: 22,
      height: 22,
    },
    iconDelete: {
      color: '#0685d1',
      width: 22,
      height: 22,
    },
    cell: {
      fontSize: 18,
    },
  })
);

export const useStylesProductTypesList = makeStyles(theme =>
  createStyles({
    empty: {
      marginBottom: 20,
      marginTop: 20,
    },
    buttons: {
      marginRight: theme.spacing(3),
    },
    card: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    categoryTree: {
      maxWidth: 400,
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    img: {
      width: 50,
      height: 50,
    },
    icon: {
      color: '#d01c1f',
      width: 22,
      height: 22,
    },
    iconDelete: {
      color: '#0685d1',
      width: 22,
      height: 22,
    },
    cell: {
      fontSize: 18,
    },
  })
);

export const useStylesProductList = makeStyles(theme =>
  createStyles({
    empty: {
      marginBottom: 20,
      marginTop: 20,
    },
    name: {
      fontSize: 20,
      fontWeight: 600,
      margin: 0,

      [theme.breakpoints.up('md')]: {
        fontSize: 34,
        lineHeight: 1,
      },
    },
    listText: {
      marginBottom: 0,
      fontSize: 17,
      fontWeight: 'bold',
      color: '#000000',
      paddingLeft: 10,
    },
    listKey: {
      marginBottom: 0,
      fontSize: 17,
      fontWeight: 400,
      color: '#757575',
    },
    imgContainer: {
      height: 400,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        maxWidth: 280,
        height: 200,
      },
      [theme.breakpoints.only('sm')]: {
        maxWidth: 350,
        height: 250,
      },
    },
    imgCom: {
      maxWidth: 90,
      [theme.breakpoints.down('xs')]: {
        maxWidth: 75,
      },
      [theme.breakpoints.only('sm')]: {
        maxWidth: 85,
      },
      cursor: 'pointer',
    },
    buttons: {
      marginRight: theme.spacing(3),
    },
    card: {
      // marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    categoryTree: {
      maxWidth: 400,
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    img: {
      maxHeight: 55,
    },
    icon: {
      width: 22,
      height: 22,
    },
    iconDelete: {
      color: '#0685d1',
      width: 22,
      height: 22,
    },
    cell: {
      fontSize: 18,
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    count: {
      fontStyle: 'bold',
      fontWeight: 600,
      color: '#000',
      marginRight: 15,
      fontSize: 18,
    },
    filterCol: {
      width: 326,
    },
    productsCol: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 'calc(100% - 326px - 15px)',
      },
    },
    revers: {
      display: 'flex',
      flexDirection: 'column-reverse',
      justifyContent: 'flex-end',
    },
    productColHeader: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 15,
    },
    productColHeaderItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    title: {
      fontStyle: 'bold',
      fontWeight: 800,
      color: '#d01c1f',
      marginRight: 15,
      fontSize: 18,
    },
  })
);

export const useTreeItemStyles = makeStyles(theme =>
  createStyles({
    root: {
      color: theme.palette.text.secondary,
      margin: 0,
      paddingLeft: 20,
      listStyle: 'none',
      '&:focus > $content, &$selected > $content': {
        backgroundColor: `var(--tree-view-bg-color)`,
        color: 'var(--tree-view-color)',
      },
      '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
        backgroundColor: 'transparent',
      },
      '&:hover > $content $label, &:hover > $content $label, &$selected > $content $label': {
        backgroundColor: 'transparent',
      },
    },
    cardCategory: {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    cardButtons: {
      justifyContent: 'flex-end',
      padding: 16,
    },
    buttons: {
      marginRight: theme.spacing(2),
    },
    button: {
      '&:active': {
        opacity: 0.2,
      },
    },
    content: {
      color: theme.palette.text.primary,
      fontWeight: 500,
      '$expanded > &': {
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    group: {
      marginLeft: 0,
    },
    expanded: {},
    selected: {
      backgroundColor: `var(--tree-view-bg-color)`,
    },
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: theme.spacing(0.7),
      paddingTop: theme.spacing(0.7),
      width: '100%',
      padding: 10,
      cursor: 'pointer',
      position: 'relative',
      transition: '.25s ease-out',
    },
    labelContainerChildren: {
      maxHeight: '100%',
      overflow: 'hidden',
    },
    labelChildren: {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: theme.spacing(0.7),
      paddingTop: theme.spacing(0.7),
      width: '100%',
      padding: 10,
      cursor: 'pointer',
      position: 'relative',
      paddingLeft: 30,
      transition: '.25s ease-out',
    },
    labelSubChildren: {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: theme.spacing(0.7),
      paddingTop: theme.spacing(0.7),
      width: '100%',
      padding: 10,
      cursor: 'pointer',
      position: 'relative',
      paddingLeft: 60,
      transition: '.25s ease-out',
    },
    labelIcon: {},
    labelTitle: {
      fontWeight: 700,
      flexGrow: 1,
      fontSize: 18,
      color: '#000000',
    },
    labelText: {
      fontWeight: 600,
      flexGrow: 1,
      fontSize: 18,
      // color: '#000000',
      opacity: 0.8,
    },
    activeItem: {
      backgroundColor: 'rgba(208, 28, 31,0.15)',
      // color: '#d01c1f',
    },
    hide: {
      height: 0,
    },
    fontNoLang: {
      fontSize: 18,
      fontWeight: 200,
    },
  })
);

export const useStylesProductListPage = makeStyles(theme =>
  createStyles({
    empty: {
      marginBottom: 20,
      marginTop: 20,
    },
    buttons: {
      marginRight: theme.spacing(3),
    },
    card: {
      // marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    categoryTree: {
      maxWidth: 400,
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    img: {
      maxHeight: 55,
    },
    countReviews: {
      fontSize: 14,
      fontFamily: 'Open Sans',
      fontWeight: 400,
      color: '#5D78FFFF',
    },
    textInput: {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: 450,
      alignItems: 'center',
    },
    icon: {
      color: '#d01c1f',
    },
    iconDelete: {
      color: '#0685d1',
    },
    cell: {
      fontSize: 18,
    },
  })
);

export const useStylesModalReview = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  input: {
    width: '100%',
    '& .MuiOutlinedInput-multiline': {
      padding: '18px 25px',
      borderColor: '#5e6278',
    },
  },
  containerButtons: {
    marginTop: 20,
    display: 'flex',
  },
  btn: {
    minWidth: 240,
  },
  btnText: {
    fontSize: 17,
    fontWeight: 'bold',
    textTransform: 'initial',
  },
  buttonFormLeft: {
    flex: 1,
    marginRight: 20,
    backgroundColor: 'transparent',
    color: '#000',
    borderColor: '#000',
    borderWidth: 1,
  },
  buttonForm: {
    flex: 1,
  },
  statisticsHeader: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  titleCreate: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 14,
  },
  titleDesc: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 19,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'rgba(0, 0, 0, 0.35) !important', // Цвет и прозрачность заднего фона
  },
  modalContent: {
    backgroundColor: 'white',
    borderRadius: '4px',
    padding: '26px 50px 30px',
    width: 600,
  },
}));
