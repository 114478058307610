import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme =>
  createStyles({
    containerPaper: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 20,
      boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
    },
    // container: {
    //   paddingTop: 50,
    //   paddingBottom: 50,
    //   [theme.breakpoints.down('md')]: {
    //     paddingLeft: 40,
    //     paddingRight: 40,
    //   },
    //   [theme.breakpoints.up('md')]: {
    //     paddingLeft: 50,
    //     paddingRight: 50,
    //   },
    // },
    container: {
      padding: '0',
      [theme.breakpoints.up('md')]: {
        padding: '0 100px',
      },
    },
    card: {
      padding: '50px',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',

      [theme.breakpoints.up('md')]: {
        padding: '50px',
        maxWidth: '1280px',
        margin: 'auto',
      },
    },
    containerTop: {
      display: 'flex',
      flexDirection: 'column',
    },
    containerBot: {
      marginTop: 35,
    },
    imgContainer: {
      height: 400,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        maxWidth: 280,
        height: 200,
      },
      [theme.breakpoints.only('sm')]: {
        maxWidth: 350,
        height: 250,
      },
    },
    name: {
      fontSize: 20,
      fontWeight: 600,
      margin: 0,
      paddingTop: 10,

      [theme.breakpoints.up('md')]: {
        fontSize: 34,
        lineHeight: 1.2,
      },
    },
    img: {
      maxWidth: 240,
      [theme.breakpoints.down('xs')]: {
        maxWidth: 170,
      },
      [theme.breakpoints.only('sm')]: {
        maxWidth: 190,
      },
      cursor: 'pointer',
    },
    listKey: {
      marginBottom: 0,
      fontSize: 17,
      fontWeight: 400,
      color: '#757575',
    },
    outKey: {
      marginBottom: 0,
      fontSize: 17,
      fontWeight: 400,
      color: '#000000',
    },
    outNum: {
      marginBottom: 0,
      fontSize: 17,
      fontWeight: 400,
      color: '#d01c1f',
    },
    listText: {
      marginBottom: 0,
      fontSize: 17,
      fontWeight: 'bold',
      color: '#000000',
      paddingLeft: 10
    },
    title: {
      marginBottom: 0,
      fontSize: 17,
      fontWeight: 'bold',
      color: '#000000',
    },
    titleOut: {
      marginBottom: 0,
      fontSize: 20,
      fontWeight: 'bold',
      color: '#000000',
    },
    subTitle: {
      marginBottom: 0,
      fontSize: 17,
      fontWeight: 600,
      color: '#000000',
    },
    num: {
      marginBottom: 0,
      fontSize: 17,
      fontWeight: 'bold',
      color: '#d01c1f',
    },
    description: {
      fontSize: 17,
      fontWeight: 400,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '23px',
      letterSpacing: 'normal',
      color: '#000000',
      whiteSpace: 'break-spaces',
      margin: 0,
    },
    galleryContainer: {
      [theme.breakpoints.down('xs')]: {
        maxWidth: 280,
        maxHeigth: 280,
      },
      [theme.breakpoints.only('sm')]: {
        maxWidth: 350,
        maxHeigth: 350,
      },
    },
    productInfo: {
      paddingLeft: 35,
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 0,
        marginTop: 25,
      },
    },
    addToCartBtn: {},
    actions: {
      marginTop: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        marginTop: 10,
      },
    },
    richEditor: {
      [theme.breakpoints.up('lg')]: {
        width: 950,
      },
    },
    richEditor1: {},
    header: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 25,
    },
    leftCol: {
      maxWidth: '690px',
      [theme.breakpoints.up('md')]: {
        width: '45%',
        marginRight: '20px',
      },
    },
    rightCol: {
      width: '100%',
      position: 'relative',
      [theme.breakpoints.up('md')]: {
        width: '45%',
      },
    },
    companyCard: {
      top: 0,
      padding: '20px 25px',
      borderRadius: '4px',
      backgroundColor: 'rgba(208, 28, 31, 0.1)',
      marginTop: 50,
    },
    statisticsHeader: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between',

      marginBottom: 22,
      [theme.breakpoints.down(500)]: {
        alignItems: 'center',

        '& p': {
          fontSize: 23,
        },
      },
    },
    statisticsList: {
      marginBottom: 8,
    },
    ratingInfoText: {
      fontSize: 17,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      margin: 0,
    },
    statisticsItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 8,
      whiteSpace: 'nowrap',
      fontWeight: 400,
      '& .progress': {
        /* Primary colors/Green */
        background: 'red',
        borderRadius: 12,
        height: 5,
        flex: 1,
        overflow: 'hidden',
        margin: '0 16px',
        '& span': {
          display: 'inline-block',
          height: '100%',
          background: 'red',
        },
      },
      [theme.breakpoints.down(500)]: {
        '& p:first-child': {
          marginRight: `16px !important`,
          color: 'red',
        },
        '& p:last-child': {
          marginLeft: `16px !important`,
          color: 'red',
        },
      },
    },
    feedButton: {
      margin: '16px 0',
    },
    btnText: {
      fontSize: 17,
      fontWeight: 'bold',
      textTransform: 'initial'
    },
    btn: {
      minWidth: 240,
    },
  })
);

export const useStylesCompanies = makeStyles(theme =>
  createStyles({
    title: {
      marginBottom: 20,
      textTransform: 'capitalize',
    },
    empty: {
      marginBottom: 20,
      marginTop: 20,
    },
    card: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    addBtn: {},
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    tariffText: {
      margin: 0,
      padding: 0,
      color: '#d01c1f',
      cursor: 'pointer',
    },
    green: {
      color: '#fff',
      // backgroundColor: '#566DC6FF',
    },
    companyNameAvatarWrapper: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    },
    bycompanyNameAvatarWrapper: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-start'
    },
    icon: {
      color: '#d01c1f',
      width: 22,
      height: 22,
    },
    iconDelete: {
      color: '#0685d1',
      width: 22,
      height: 22,
    },
    cell: {
      fontSize: 18,
    },
  })
);
