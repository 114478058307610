import React, { ChangeEvent } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  createStyles,
  makeStyles,
  Card,
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import { ICompany } from '../../../../interfaces/companies';
import { toAbsoluteUrl } from '../../../../../_metronic';
import { API_DOMAIN } from '../../../../constants';
import { useFormatMessage } from '../../../../hooks';
import { IOrderItem } from '../../../../interfaces/order';
import { thousands } from '../../../../utils/utils';
import Preloader from '../../../../components/ui/Preloader/Preloader';

interface IAlertCompanyPayments extends DialogProps {
  handleAgree?: any;
  handleClose?: any;
  message?: string;
  company?: ICompany | null;
  prices?: IOrderItem[] | null
  orderId: number | undefined
}

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      padding: '20px 20px',
      width: 700
    },
    paper: {
      overflowY: 'clip'
    },
    input_file: {
      position: 'relative',
      display: 'inline-block',
      height: '40px',
      padding: '20px'
    },
    span: {
      position: 'relative',
      display: 'inline-block',
      cursor: 'pointer',
      outline: 'none'
    },
    input: {
      position: 'absolute',
      zIndex: -1,
      opacity: 0,
      display: 'block',
      width: 0,
      height: 0,
    },
    imgContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    img: {
      maxWidth: 240,
      [theme.breakpoints.down('xs')]: {
        maxWidth: 170,
      },
      [theme.breakpoints.only('sm')]: {
        maxWidth: 190,
      },
      cursor: 'pointer',
    },
    listKey: {
      marginBottom: 0,
      fontSize: 17,
      fontWeight: 'normal',
      color: '#757575',
    },
    title: {
      marginBottom: 0,
      fontSize: 17,
      fontWeight: 400,
      color: '#000000',
    },
    name: {
      fontSize: 34,
      fontWeight: 'bold',
      margin: 0,
      paddingTop: 10,
      color: '#000000',
      [theme.breakpoints.up('md')]: {
        fontSize: 34,
        lineHeight: 1.2,
      },
    },
    listText: {
      marginBottom: 0,
      fontSize: 17,
      fontWeight: 'bold',
      color: '#000000',
      paddingLeft: 10
    },
    num: {
      marginBottom: 0,
      fontSize: 17,
      fontWeight: 'bold',
      color: '#000000',
    },
    description: {
      fontSize: 17,
      fontWeight: 400,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '23px',
      letterSpacing: 'normal',
      color: '#000000',
      whiteSpace: 'break-spaces',
      margin: 0,
    },
    titleOut: {
      marginBottom: 0,
      fontSize: 20,
      fontWeight: 'bold',
      color: '#000000',
    },
    outKey: {
      marginBottom: 0,
      fontSize: 17,
      fontWeight: 400,
      color: '#000000',
    },
    outNum: {
      marginBottom: 0,
      fontSize: 17,
      fontWeight: 400,
      color: '#000000',
    },
    subTitle: {
      marginBottom: 0,
      fontSize: 17,
      fontWeight: 600,
      color: '#000000',
    },
    cansel_btn: {
      width: 25,
      height: 25,
      backgroundColor: '#d01c1f',
      borderRadius: 4,
      position: 'absolute',
      top: 10,
      right: 10,
    },
  })
);

const AlertCompanyPayments: React.FC<IAlertCompanyPayments> = ({
  open,
  handleClose,
  message = '',
  company,
  prices,
  orderId
}) => {
  const classes = useStyles();
  const fm = useFormatMessage();

  const totalPriceByOrder = () => {
    let totalOrderPrice = 0;
    prices?.forEach(item => {
        totalOrderPrice += item.product.price * item.count;
    });
    return totalOrderPrice;
};

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      scroll='body'
    >
      <DialogActions style={{ overflow: 'hidden' }} >
        {company && prices ? (
          <div style={{position: 'relative'}} className={classes.container}>
          <div className={classes.cansel_btn}>
            <CloseIcon
              style={{
                color: '#ffffff',
                fontSize: 24,
                marginTop: 1.2,
                cursor: 'pointer',
                marginLeft: 0.5
              }}
              onClick={handleClose}
            />
          </div>

          <p className={classes.name}>
            <b>{thousands(String(totalPriceByOrder()))}</b>
          </p>
          <p style={{marginBottom: 15}} className={classes.listKey}>Сумма платежа</p>

          <p className={classes.num}>
            <b>Оплата заказа №{orderId} на сайте opt.ru</b>
          </p>
          <p style={{marginBottom: 15}} className={classes.listKey}>Назначение платежа</p>

          {company?.payment_details && (
            <>
            <p className={classes.listKey}>Реквизиты для оплаты:</p>
            <p
              className={classes.description}
              dangerouslySetInnerHTML={{ __html: company.payment_details }}
            />
            </>
          )}
        </div>
        ): <Preloader />}
        
      </DialogActions>
    </Dialog >
  )
};

export default AlertCompanyPayments;
