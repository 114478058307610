import React, { useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

import { useStyles } from './hooks/useStyles';
import { toAbsoluteUrl } from '../../../../_metronic';
import { TUrl } from '../../ui/UploadImages';
import { CroppedImage } from '../CroppedImage';
import { Modal } from '../../other/Modals';

interface IProps {
  uploadFiles: (files: File[]) => void;
  avaliableNumberOfFilesToUpload?: number;
  title?: string;
  description?: string;
  withCrop?: boolean;
  cropAspect?: number;
}

const FilesDropzone: React.FC<IProps> = ({
  uploadFiles,
  avaliableNumberOfFilesToUpload = 1,
  title,
  description,
  withCrop = false,
  cropAspect,
}) => {
  const intl = useIntl();

  title = title || intl.formatMessage({ id: 'COMMON.PHOTO.LOAD' });
  description = description || intl.formatMessage({ id: 'COMMON.PHOTO.DESC' });

  const classes = useStyles();

  const [imageBeforeCrop, setImageBeforeCrop] = useState<TUrl | null>(null);

  const handleDrop = useCallback(
    async droppedFiles => {
      uploadFiles(droppedFiles.slice(0, avaliableNumberOfFilesToUpload));
    },
    [avaliableNumberOfFilesToUpload]
  );

  const handleDropWithCrop = useCallback(async droppedFiles => {
    setImageBeforeCrop(URL.createObjectURL(droppedFiles[0]));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: withCrop ? handleDropWithCrop : handleDrop,
    accept: {
      // @ts-ignore
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg'],
    },
  });

  const isDropZoneActive = avaliableNumberOfFilesToUpload > 0;

  const dropZoneProps = useMemo(() => {
    if (!isDropZoneActive) return {};
    return getRootProps();
  }, [avaliableNumberOfFilesToUpload, getRootProps]);

  return (
    <div className={clsx(classes.root)}>
      <div
        className={clsx({
          [classes.dropZone]: true,
          [classes.dropZoneActive]: isDropZoneActive,
          [classes.dragActive]: isDragActive,
        })}
        {...dropZoneProps}
      >
        <input {...getInputProps()} />
        <div>
          <img
            alt='Select file'
            className={classes.image}
            src={toAbsoluteUrl('/images/undraw-add-file-2-gvbb.svg')}
          />
        </div>
        <div className={classes.content}>
          <Typography gutterBottom style={{ marginBottom: 0, fontSize: 32, fontWeight: 600 }}>
            {title}
          </Typography>
          <Typography className={classes.info} color='textSecondary' variant='body1'>
            {description}. Max files: {avaliableNumberOfFilesToUpload}
          </Typography>
        </div>
      </div>
      {withCrop && (
        <Modal
          open={!!imageBeforeCrop}
          onClose={() => setImageBeforeCrop(null)}
          title=''
          content={
            <CroppedImage
              aspect={cropAspect}
              src={imageBeforeCrop || ''}
              onSubmit={(croppedImg: File) => {
                handleDrop([croppedImg]);
                setImageBeforeCrop(null);
              }}
            />
          }
        />
      )}
    </div>
  );
};

export default FilesDropzone;
