export function googleDataToEntities(data) {
  try {
    const res = data.map(i => ({
      address: i.GeoObject.metaDataProperty.GeocoderMetaData.Address.formatted,
      lat: Number(i.GeoObject.Point.pos.split(' ')[1]),
      lng: Number(i.GeoObject.Point.pos.split(' ')[0]),
    }));
    return res;
  } catch (e) {
    return [];
  }
}
