import React, { Suspense, lazy, useEffect, useState } from 'react';
import {Redirect, Route, Switch, useHistory, useLocation} from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { useIntl } from 'react-intl';

import { LayoutSplashScreen } from '../../../_metronic';
import getMenuConfig from '../../router/MenuConfig';
import * as builder from '../../../_metronic/ducks/builder';
import { actions as i18nActions } from '../../../_metronic/ducks/i18n';
import { actions as cartActions } from '../../store/ducks/cart.duck';
import { actions as profileActions } from '../../store/ducks/profile.duck';
import { IAppState } from '../../store/rootDuck';
import { UserRoles } from '../../interfaces/user';
import Preloader from '../../components/other/Preloader/Preloader';
import CompanyView from './companies/CompanyView';
import ProductView from './products/ProductView';
import BlogPage from './blog';

const CategoriesPage = lazy(() => import('./categories'));
const UsersPage = lazy(() => import('./users'));
const PromocodesPage = lazy(() => import('./promocodes'));
const CompaniesPage = lazy(() => import('./companies'));
const UserDocPage = lazy(() => import('./userDocs'));
const SettingsPage = lazy(() => import('./settings'));
const ProductsPage = lazy(() => import('./products'));
const OrdersPage = lazy(() => import('./orders'));
const StorePage = lazy(() => import('./store'));
const CartPage = lazy(() => import('./cart'));
const TariffsPage = lazy(() => import('./tariffs'));
const CollectionsPage = lazy(() => import('./collections'));
const TransactionsPage = lazy(() => import('./transactions'));
const RefferalsPage = lazy(() => import('./referrals'));
const ChatsPages = lazy(() => import('./chats'));
const TenderPage = lazy(() => import('./tenders'));
const RouteProduct = lazy(() => import('./products/RouteProduct'));

const HomePage: React.FC<TPropsFromRedux & { userLastLocation: any }> = ({
  userRoles,
  isAuthorized,
  setMenuConfig,
  getOrCreateCart,
  getOrCreateCartLoading,
  getLocalGuestCart,
  isAdmin,
  fetchMe,
  me,
  setLanguage,
}) => {
  const [menuConfig] = useState(getMenuConfig(userRoles, isAuthorized));
  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();

  // for user profile in header
  useEffect(() => {
    fetchMe();
  }, []);

  useEffect(() => {
    if (me?.locale && me?.locale !== intl.locale) {
      setLanguage(me.locale);
    }
  }, [me]);

  useEffect(() => {
    setMenuConfig(menuConfig);
  }, [setMenuConfig, menuConfig]);

  // Проверка на существование корзины у пользователя
  useEffect(() => {
    const localCart = localStorage.getItem('guestCart');
    if (userRoles && userRoles.includes(UserRoles.ROLE_BUYER) && localCart) {
      const localLocation = localStorage.getItem('location');
      const parseLocalLocation = localLocation && JSON.parse(localLocation);
      if (parseLocalLocation) {
        localStorage.removeItem('location');
      }
      // history.replace('/cart');
    }

    if (userRoles && !userRoles.includes(UserRoles.ROLE_VENDOR) && !isAdmin) {
      getOrCreateCart();
    }
  }, [getOrCreateCart, isAdmin, userRoles]);

  useEffect(() => {
    !isAuthorized && getLocalGuestCart();
  }, [isAuthorized, getLocalGuestCart]);

  // useEffect(() => {
  //   if (!isAuthorized) {
  //     history.push('/auth/main')
  //   }
  // }, [isAuthorized])

  if (getOrCreateCartLoading) return <Preloader />;

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /catalog. */
          // isAuthorized ? (
          //   <Redirect exact from='/' to='products/catalog' />
          // ) : (location.pathname !== "/products/catalog") && (
          //     <Redirect to='/auth/main' />
          // )
        }
        <Redirect exact from='/' to='/products/catalog' />
        <Route path='/settings' component={SettingsPage} />

        <Route path='/categories' component={CategoriesPage} />
        <Route path='/products' component={ProductsPage} />
        <Route path='/documents' component={UserDocPage} />
        <Route path='/users' component={UsersPage} />
        <Route path='/companies' component={CompaniesPage} />
        <Route path='/orders' component={OrdersPage} />
        <Route path='/stores' component={StorePage} />
        <Route path='/cart' component={CartPage} />
        <Route path='/blog' component={BlogPage} />
        <Route path='/viewproduct/:id' component={ProductView} />
        <Route path='/viewcompany/:id' component={CompanyView} />
        <Route path='/tariffs' component={TariffsPage} />
        <Route path='/collections' component={CollectionsPage} />
        <Route path='/promocodes' component={PromocodesPage} />
        <Route path='/refferals' component={RefferalsPage} />
        <Route path='/transactions' component={TransactionsPage} />
        <Route path='/chats' component={ChatsPages} />
        <Route path='/product_search' component={TenderPage} />
        <Route path='/product' component={RouteProduct} />

        <Redirect to='/error/error-v1' />
      </Switch>
    </Suspense>
  );
};

const connector = connect(
  (state: IAppState) => ({
    userRoles: state.auth.user?.roles,
    isAuthorized: state.auth.user != null,
    getOrCreateCartLoading: state.cart.getOrCreateCartLoading,
    isAdmin: state.auth.user?.is_admin,
    me: state.profile.me,
  }),
  {
    fetchMe: profileActions.fetchRequest,
    editMe: profileActions.editRequest,
    setMenuConfig: builder.actions.setMenuConfig,
    getOrCreateCart: cartActions.getOrCreateRequest,
    getLocalGuestCart: cartActions.getLocalGuestCart,
    setLanguage: i18nActions.setLanguage,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(HomePage);
