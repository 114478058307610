import React from 'react';

interface IProps {
  size?: string;
}

const StarActive: React.FC<IProps> = ({ size }) => {
  return (
    <>
      <svg width={size || "25"} height={size || "25"} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
        <path d="m12.5 18.75-7.347 3.863 1.403-8.182L.612 8.637l8.214-1.193L12.5 0l3.674 7.444 8.214 1.193-5.944 5.794 1.403 8.182z" fill="#D01C1F" />
      </svg>

    </>
  );
};

export default StarActive;
