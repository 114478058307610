import React from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import * as builder from '../../ducks/builder';
import Icon1 from './Icon1';
import Icon2 from './Icon2';
import { Button } from '@material-ui/core';

class Footer extends React.Component {
  render() {
    const today = new Date().getFullYear();
    return (
      <div
        className={`kt-footer ${this.props.footerClasses} kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop`}
        id='kt_footer'
      >
        <div className={`kt-container ${this.props.footerContainerClasses}`}>
          <div>
            <span className='kt-footer__copyright'>
              <div className='span'>
                <a href='tel:8 800 201 03 50' rel='noopener noreferrer' className='kt-link'>
                  <span style={{ color: '#d01c1f' }}> 8 800 201 03 50 </span>
                </a>

                <br />

                <span style={{ fontSize: 14, color: '#000000' }}>Звонок бесплатный</span>
              </div>
              &nbsp;&nbsp;&nbsp;
              <div className='span'>
                <a href='tel:8 964 965 28 28' rel='noopener noreferrer' className='kt-link'>
                  <span style={{ color: '#d01c1f' }}>8 964 965 28 28</span>
                </a>

                <br />

                <span style={{ fontSize: 14, color: '#000000' }}>Всегда поможем Вам</span>
              </div>
              <br />
            </span>
            {/*<span style={{fontSize: 14, color: '#000000', fontWeight: 'normal'}} className='span'>*/}
            {/*  Пользовательское соглашение | Политика конфиденциальности*/}
            {/*</span>*/}
            <a
              rel='noopener noreferrer'
              className='kt-link'
              href={'/documents/legacy/read'}
              style={{ fontSize: 14, color: '#000000', fontWeight: 'normal' }}
              // onClick={() => this.handleDialogOpen('user_agreement')}
            >
              &nbsp;&nbsp;Пользовательское соглашение
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <a
              href={'/documents/privacy/read'}
              // onClick={() => this.handleDialogOpen('privacy_policy')}
              rel='noopener noreferrer'
              className='kt-link'
              style={{ fontSize: 14, color: '#000000', fontWeight: 'normal' }}
            >
              Политика конфиденциальности
            </a>
            <br />
            {/*<span*/}
            {/*  style={{ fontSize: 14, color: '#000000', fontWeight: 'normal' }}*/}
            {/*  className='span'*/}
            {/*>*/}
            {/*  Разработка: Start Mobile*/}
            {/*</span>*/}
            <a
              style={{ fontSize: 14, color: '#000000', fontWeight: 'normal' }}
              href='https://start-mobile.net/ru/sozdanie-marketplejsa-razrabotka-pod-klyuch-cena/'
              target='_blank'
              rel='noopener noreferrer'
              className='kt-link'
            >
              &nbsp;&nbsp;Разработка: Start Mobile
            </a>
          </div>

          <div style={{marginRight: 20}}>
            <span
              style={{ fontSize: 14, color: '#000000', fontWeight: '600' }}
              className='span'
            >
              Мобильное приложение продавца и покупателя
            </span>

            <div className='kt-footer__menu'>
              <Button
                onClick={() => {
                  window.location.href =
                    'https://apps.rustore.ru/app/us.startmobile.wholesale';
                }}
                style={{ padding: 0, height: 50, marginRight: 25 }}
              >
                <Icon1 />
              </Button>

              <Button
                onClick={() => {
                  window.location.href =
                    'https://apps.apple.com/ru/app/одежда-оптом/id6476561188';
                }}
                style={{ padding: 0, height: 50, marginRight: 20 }}
              >
                <Icon2 />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  fluid: objectPath.get(store.builder.layoutConfig, 'footer.self.width') === 'fluid',
  footerClasses: builder.selectors.getClasses(store, {
    path: 'footer',
    toString: true,
  }),
  footerContainerClasses: builder.selectors.getClasses(store, {
    path: 'footer_container',
    toString: true,
  }),
});

export default connect(mapStateToProps)(Footer);
