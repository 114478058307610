import React from 'react';
import { makeStyles, createStyles, IconButton, useTheme, Button } from '@material-ui/core';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      flexShrink: 0,
      display: 'flex',
      alignItems: 'center',
      marginLeft: theme.spacing(2.5),
      marginRight: 12,
    },
    pageButton: {
      margin: theme.spacing(0, 0.5),
    }
  })
);

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onChangePage: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const numPages = Math.ceil(count / rowsPerPage);
  
  const visiblePages = [];
  if (numPages <= 5) {
      for (let i = 0; i < numPages; i += 1) {
          visiblePages.push(i);
      }
  } else if (page <= 2) {
      for (let i = 0; i < 5; i += 1) {
          visiblePages.push(i);
      }
  } else if (page >= numPages - 2) {
      for (let i = numPages - 5; i < numPages; i += 1) {
          visiblePages.push(i);
      }
  } else {
      visiblePages.push(page - 2);
      for (let i = page - 2; i < page + 3; i += 1) {
          visiblePages.push(i);
      }
  }

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, numPages - 1);
  };

  const handlePageNumberClick = (event: React.MouseEvent<HTMLButtonElement>, pageNumber: number) => {
    onChangePage(event, pageNumber);
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      {visiblePages.map((pageNumber, index) => (
        <Button
          key={index}
          onClick={(event) => handlePageNumberClick(event, pageNumber)}
          disabled={pageNumber === page}
          className={classes.pageButton}
        >
          {pageNumber + 1}
        </Button>
      ))}
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= numPages - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= numPages - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

export default TablePaginationActions;
